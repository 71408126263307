@import '../../basics/mixins';

.wrap {
  padding: 20px;
}
.header {
  display: flex;
  justify-content: space-between;
  gap: 0 12px;

  &_title {
    color: var(--text-basic-default);
    line-height: 2.8rem;
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-bold);
  }
  &_desc {
    margin-top: 2px;
    color: var(--text-basic-description);
    line-height: 2.1rem;
    font-size: var(--font-size-13);
  }
  &_expand {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-top: 8px;

    &.valid {
      gap: 0 2px;
      width: auto !important;
      height: 28px;
      padding: 0 8px 0 10px;
      border-radius: 28px;
      background: var(--background-success-secondary);
      color: var(--text-success-bolder);
      font-size: var(--font-size-13);
      font-weight: var(--font-weight-black);
    }
    &.invalid {
      width: 48px !important;
      height: 48px;
      border-radius: 6px;

      span{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: var(--background-success-disabled);
      }
    }
  }
}
.info {
  display: none;

  &.unfold {
    display: block;
  }
}
.status {
  @include ellipsis;
  width: 100%;
  margin-top: 16px;
  padding: 12px;
  border-radius: 6px;
  box-sizing: border-box;
  background: var(--background-surface-secondary-base);
  color: var(--text-basic-description);
  line-height: 2.4rem;
  font-size: var(--font-size-13);

  img {
    vertical-align: top;
  }
  span {
    margin-left: 10px;
  }
  em {
    color: var(--text-basic-accent);

  }
}
.list {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  margin: 16px 0;

  &_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0 6px;

    &_insure {
      display: flex;
      gap: 0 10px;
      align-items: center;
    }
    &_name {
      @include multiline-ellipsis(1);
      color: var(--text-basic-default);
      font-size: var(--font-size-13);
    }
    & > em {
      overflow: hidden;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      width: auto !important;
      height: 28px;
      font-size: var(--font-size-12);
    }
    &_view {
      gap: 0 2px;
      height: 28px;
      padding: 0 8px 0 10px;
      border-radius: 28px;
      background: var(--background-button-primary);
      color: var(--text-basic-inverse);
    }
    &_condition {
      padding-left: 4px;
      color: var(--text-basic-caption);
    }
  }
}
.btn_more {
  display: flex;
  align-items: center;
  justify-content: center;
}
