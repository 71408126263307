@charset "UTF-8";

// animation
// TransitionActions 타입에 따라 classname 지정 (ex : appear -> appear-active)

// 메인 인기혜택 전체 팝업
.full-slide-up {
  &-enter,
  &-appear,
  &-exit-done {
    transform: translate(0, 0) !important;
    transition: all 300ms ease-out;
  }
  &_appear-active,
  &-enter-active {
    transform: translate(0, 0) !important;
    transition: all 300ms ease-out;
  }
  &-enter-done,
  &-exit {
    top:0;
    transition: all 300ms ease-out;
  }
  &-exit-active {
    transform: translate(0, 100%) !important;
    transition: all 300ms ease-out;
  }
}

.bottom-full-slide-up {
  &-enter,
  &-appear {
    transform: translateY(100%) !important;
  }

  &_appear-active,
  &-enter-active {
    opacity: 1;
    transform: translateY(0) !important;
    transition: all 200ms ease-in-out;
  }

  &-enter-done {
    opacity: 1;
    transform: translateY(0) !important;
    transition: all 200ms ease-in-out;
  }

  &-exit {
    opacity: 0;
    transform: translateY(50%)  !important;
    transition: all 200ms ease-in-out;
  }

  &-exit-active {
    opacity: 0;
    transform: translateY(50%) !important;
    transition: all 200ms ease-in-out;
  }

  &-exit-done {
    opacity: 0;
    transform: translateY(50%) !important;
    transition: all 200ms ease-in-out;
  }
}

// 메인 팝업 애니메이션
.slide-up {
  &-enter,
  &-appear,
  &-exit-done {
    opacity: 0;
    transform: translate(-50%, 100%) !important;
  }
  &_appear-active,
  &-enter-active {
    opacity: 1;
    transform: translate(-50%, 0) !important;
    transition: all 300ms ease-out;
  }
  &-enter-done,
  &-exit {
    opacity: 1;
    transform: translate(-50%, 0) !important;
    transition: all 300ms ease-out;
  }
  &-exit-active {
    opacity: 0;
    transform: translate(-50%, 100%) !important;
    transition: all 300ms ease-out;
  }
}

.slide-up-center {
  &-enter,
  &-appear,
  &-exit-done {
    opacity: 0;
    transform: translate(-50%, 100%) !important;
  }
  &_appear-active,
  &-enter-active {
    opacity: 1;
    transform: translate(-50%, -50%) !important;
    transition: all 300ms ease-out;
  }
  &-enter-done,
  &-exit {
    opacity: 1;
    transform: translate(-50%, -50%) !important;
    transition: all 300ms ease-out;
  }
  &-exit-active {
    opacity: 0;
    transform: translate(-50%, 100%) !important;
    transition: all 300ms ease-out;
  }
}

.bottom-slide-up {
  &-enter,
  &-appear,
  &-exit-done {
    opacity: 0;
    transform: translate(-50%, 100%) !important;
    transition: all 200ms ease-out;
  }
  &_appear-active,
  &-enter-active {
    opacity: 1;
    transform: translate(-50%, 0) !important;
    transition: all 200ms ease-out;
  }
  &-enter-done,
  &-exit {
    opacity: 1;
    transform: translate(-50%, 0) !important;
    transition: all 200ms ease-out;
  }
  &-exit-active {
    opacity: 0;
    transform: translate(-50%, 100%) !important;
    transition: all 200ms ease-out;
  }
}

.fade-in {
  &-enter,
  &-exit-done {
    opacity: 0;
  }
  &-enter-active {
    opacity: 1;
    transition: all 200ms ease-in-out;
  }
  &-enter-done,
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: all 200ms ease-in-out;
  }
}

// 팝업 dimmed 영역 fade-in 애니메이션
.dimmed-fade-in {
  &-appear {
    opacity: 0;
  }
  &-appear-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
}

.slide-down {
  &-enter,
  &-exit-done {
    opacity: 0;
    transform: translate(0, -50%) !important;
    transition: all 400ms ease-in-out;
  }
  &-enter-active {
    opacity: 1;
    transform: translate(0, 0) !important;
    transition: all 400ms ease-in-out;
  }
  &-enter-done {
    opacity: 1;
    animation-name: bounce;
    animation-delay: 1s;
    animation-duration: 0.8s;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 3;
  }
  &-exit {
    opacity: 1;
    transform: translate(0, 0) !important;
    transition: all 400ms ease-in-out;
  }
  &-exit-active {
    opacity: 0;
    transform: translate(0, -50%) !important;
    transition: all 400ms ease-in-out;
  }
}

.opacity-slide-down {
  &-enter,
  &-exit-done {
    opacity: 0;
    transform: translate(0, -50%) !important;
    transition: all 400ms ease-in;
  }
  &-enter-active {
    opacity: 1;
    transition: height 400ms ease-in-out 800ms, opacity 400ms ease-in-out 1s;
  }
  &-enter-done {
    opacity: 1;
  }
  &-exit {
    opacity: 1;
    transform: translate(0, 0) !important;
    transition: all 400ms ease-in-out;
  }
  &-exit-active {
    opacity: 0;
    transition: opacity 400ms ease-in-out 0s, height 400ms ease-in-out 200ms;
  }
}

.fade-out {
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: opacity 700ms;
  }
}

.fade-header-title-anime {
  &-enter,
  &-leave-to {
    opacity: 0;
    will-change: opacity;
  }

  &-enter-active,
  &-leave-active {
    transition: all 0.15s ease-out;
    will-change: opacity;
  }
}
