@charset "UTF-8";

.settings {
  &_btn {
    padding: 24px 20px;
    font-size: var(--font-size-16);
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 20px;
      height: 1px;
      width: calc(100% - 20px * 2);
      border-bottom: 1px solid var(--gray-50);
    }
  }
  &_arrow {
    @include position(50%, 16px);
    transform: translateY(-50%);
  }

  &_otp {
    margin: 40px 20px;
    text-align: center;
    color: var(--gray-700);
    line-height: 2rem;
    font-size: var(--font-size-14);
    word-break: keep-all;

    &_reload {
      position: absolute !important;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px !important;
      height: 52px;
    }
    &_number {
      display: inline-block;
      position: relative;
      margin-top: 40px;
      font-size:  4.6rem;
      font-weight: var(--font-weight-black);
      color: var(--text-basic-default);
      letter-spacing: -1.38px;
      line-height: 6rem;

      &_copy {
        position: absolute !important;
        right: -43px;
        bottom: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px !important;
        height: 40px;
      }
    }
    &_time {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      font-size: var(--font-size-14);
      color: var(--text-basic-caption);
      line-height: 2.8rem;

      span {
        margin: 0 4px;
        color: var(--text-basic-accent);
        font-size: var(--font-size-20);
      }
      .warning {
        font-size: var(--font-size-20);
        color: var(--red-500);
      }
    }
    &_progress_bar {
      overflow: hidden;
      position: relative;
      height: 10px;
      margin: 20px 15px 0;
      border-radius: 10px;
      background: var(--gray-100);

      .gauge {
        position: absolute;
        height: 100%;
        border-radius: inherit;
        animation: progress 90s linear;
        transition: width 1s;
        background: var(--palette-tmobiblue-neutral);

        @keyframes progress {
          0% {
            width: 100%;
          }
          100% {
            width: 0;
          }
        }
        &.warning {
          background-color: var(--red-500);
        }
      }
    }
  }

  &_withdrawal {
    margin: 120px 20px;
    text-align: center;

    &_title {
      margin-top: 32px;
      font-size: var(--font-size-18);
      font-weight: var(--font-weight-bold);
      color: var(--gray-700);
    }
    &_desc {
      margin-top: 8px;
      color: var(--gray-500);
      line-height: 2.4rem;
      em {
        color: var(--gray-900);
        font-weight: var(--font-weight-bold);
      }
    }
  }

}
