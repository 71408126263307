@charset "UTF-8";

// layer
.layer {
  &_wrap {
    @include position(0,0,0,0,var(--Z-layer),fixed);
    background-color: var(--C-black-tr35);
    text-align: center;
    touch-action: none;

    &_forward {
      z-index: var(--Z-loading-indicator);
    }
  }
}

// popup
.popup {
  &_wrap {
    &.popup_bottom {
      @include position(initial,0,0,0);
      border-radius: 10px 10px 0 0;
      width: 100%;
      max-width: 400px;
      max-height: initial;
      margin: initial;
      left: 50%;
      transform: translateX(-50%);

      @media all and ( orientation: landscape ) {
        max-width: 100%;
        top: 0;
        border-radius: 0;
      }
    }

    &.popup_bottom_float {
      @include position(initial,20px,20px,20px);
      border-radius: 20px;
      width: calc(100% - 40px);
      max-width: 400px;
      left: 50%;
      transform: translateX(-50%);
      margin: initial;
    }

    &.toast {
      background-color: var(--C-gray-900);
      border-radius: 8px;
      color: var(--C-white);
      font-size: var(--font-size-16);
      z-index: 10001;
    }

    &.popup_full {
      @include position(0,0,0,0);
      height: 100%;
      max-height: initial;
      max-width: initial;
      border-radius: 0;
      transform: translate(0,0);
    }
    &.popup_ad {
      @include position(50%, null, null, 50%);
      width: 310px;
      max-width: 310px;
      border-radius: 8px;
    }

    @extend %flexbox-v;
    @include overflowHide();
    @include position(50%,25px,null,25px);
    max-height: 80%;
    max-width: 560px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: var(--C-white);
    margin: auto;
    touch-action: none;
    transform: translate(0,-50%);
  }
  &_cont {
    .type_input & {
      padding-bottom: 100px;
    }
    &_image {
      width: 310px;
      height: 380px;
    }
    &_toast {
      color: var(--C-primary-400);
      font-weight: var(--font-weight-bold);
      width: 65px;
    }
    @include overflowHide();
    position: relative;
    @extend %flexbox-stretch-v;
    -webkit-overflow-scrolling: touch;
    text-align: left;
    &.cont_centered  {
      @include position(0,0,0,0);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-y: scroll;
    }
  }
  &_subject {
    display: block;
    padding-top: 35px;
    &.toast {
      padding: 14px 20px;
      display: flex;
      justify-content: space-between;
      color: var(--C-white);
    }
    &.no_padding {
      padding-top: 0;
    }
  }
  &_content {
    .center {
      padding: 12px 20px 0;
      text-align: center;
      color: var(--gray-500);
    }
    .popup_terms_form &{
      padding: 18px 20px 0;

      @media all and ( orientation: landscape ) {
        padding: 0 20px 0;
        overflow-y: scroll;
        height: calc(100% - 120px);

        &::after {
          @include position(null, 0, 72px, 0);
          content: '';
          height: 20px;
          background: linear-gradient(360deg, #FFF 10%, rgba(255, 255, 255, 0.00) 100%);
        }

      }
    }
  }
  &_title {
    .popup_bottom & {
      padding: 24px 20px 8px;
    }
    .default & {
      padding-top: 32px;
    }
    background-color: var(--C-white);
    padding: 35px 20px 0;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-18);
    color: var(--C-gray-900);
    line-height: 1.28;
    text-align: center;
  }
  &_message {
    padding: 0 20px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-18);
    text-align: center;
    .sub {
      margin-top: 16px;
      font-size: var(--font-size-14);
      color: var(--C-gray-500);
    }
    .default & {
      font-weight: var(--font-weight-normal);
    }
    &_warning {
      @include use-svg(img-negative,42px,42px,0 50% 18px, block,$type: top);
    }
  }
  &_btn {
    &_wrap {
      .popup_bottom & {
        position: static;
        padding: 35px 20px 20px;
      }
      .popup_terms_form & {
        padding-top: 28px;
        @media all and ( orientation: landscape ) {
          position: absolute;
          bottom: 0;
        }
      }
      @extend %flexbox;
      @include position(null,0,0,0,10,sticky);
      padding: 35px 0 0;

      &.no_padding {
        padding: 0;
      }
      &.type_flex_between {
        & > button:not(:first-of-type) {
          margin-left: 8px;
        }
        .inactive:disabled {
          background: var(--C-primary-500);
          color: var(--C-white);
        }
      }
      .default & {
        padding-top: 32px;
      }
      &.column_reverse {
        border-bottom: 16px;
        flex-direction: column-reverse;

        & > button {
          font-size: var(--font-size-18);
        }
        & > button:not(:first-of-type) {
          margin-top: 8px;
        }
      }
      &.single_terms {
        display: flex;
        button {
          flex: 2;

          &:first-of-type {
            flex: 1;
          }
        }
      }
    }
    &_text {
      height: 56px;
      padding: 0 20px;
      color: var(--C-gray-500);

      &.em {
        color: var(--C-gray-700);
      }
    }
  }
  &_form {
    width: 230px;
    margin: 0 auto;

    &_wrap {
      &:after {
        @include position(51px,0,null,0);
        height: 1px;
        width: 100%;
        background-color: var(--C-gray-200);
        content: '';
      }
      .type_active &:after {
        background-color: var(--C-primary-500);
      }
      .type_error &:after {
        background-color: var(--C-red-500);
      }
      position: relative;
      box-sizing: border-box;
      margin-top: 12px;
      padding: 14px 25px 14px 0;
    }
    &_input {
      &:placeholder-shown {
        & + .btn_image_delete,
        & ~ .btn_image_delete {
          display: none;
        }
      }
      width: 100%;
      border: 0;
      font-size: var(--font-size-18);
      color: var(--C-black);
      line-height: 1.34;
      outline: 0;
    }
    &_message {
      .type_error & {
        display: block;
      }
      display: none;
      padding-top: 4px;
      font-size: 1.0rem;
      color: var(--C-red-500);
      line-height: 1.2;
    }
  }
  &_terms{
    &_wrap{
      > .cont_centered {
        align-items: flex-start;
        justify-content: flex-start;
      }
      .terms{
        &_close-btn {
          width: 34px;
          height: 34px;
          position: absolute;
          top: 11px;
          right: 3px;
        }
        &_title {
          font-weight: var(--font-weight-bold);
          font-size: var(--font-size-14);
          padding: 76px 20px 0;
          letter-spacing: -0.6px;
          text-align: left;
          &.type_big {
            padding: 40px 20px 20px;
            font-size: var(--font-size-18);
          }
        }
        &_text {
          margin-top: 36px;
          padding: 0 20px;
          color: var(--C-gray-700);
          letter-spacing: -0.7px;
          font-size: var(--font-size-14);

          &_line-break {
            display: flex;
            &_text {
              width: calc(100% - 65px);
            }
          }
        }
        &_sub-title {
          font-size: var(--font-size-14);
          color: var(--C-gray-900);
          margin: 0 20px;
        }
        &_table {
          display: table;
          border-collapse: collapse;
          margin: 16px 20px 0;
          font-size: var(--font-size-12);
          &_body {
            display: table-row-group;
            border: solid 1px var(--C-gray-200);
          }
          &_row {
            display: table-row;
          }
          &_cell {
            &.title {
              width: 100px;
              vertical-align: middle;
              text-align: center;
              border-right: solid 1px var(--C-gray-200);
              border-bottom: solid 1px var(--C-gray-200);
            }
            border-bottom: solid 1px var(--C-gray-200);
            display: table-cell;
            padding: 16px 12px;
          }
        }
        &_notice {
          font-size: var(--font-size-12);
          color: var(--C-gray-500);
          margin: 16px 20px 20px;
        }
      }
      &.type_driving_history{
        background: var(--C-gray-100);
        .terms{
          &_title{
            font-weight: var(--font-weight-normal);
            font-size: 2.3rem;
            color: var(--C-black);
            padding-top: 50px;
            letter-spacing: -0.15rem;
            line-height: 3.6rem;
            .type_point{
              font-size: inherit;
              color: var(--C-primary-500);
            }
          }
          &_text{
            font-size: var(--font-size-14);
            margin-bottom: 22px;
            &_title{
              color: var(--C-black);
              font-size: 1.7rem;
            }
            .type_medium {
              margin-bottom: 22px;
            }
            &_desc{
              margin-bottom: 25px;
              line-height: 2rem;
              &:last-child{
                margin-bottom: 0;
              }
            }
            &:last-child{
              margin-top: 15px;
            }
          }
        }
      }
    }
    &_form{
      &.active{
        opacity: 1;
        transform: translateY(0px) !important;
      }
      .popup_title{
        text-align: left;
        white-space: pre-wrap;
        font-size: var(--font-size-20);
        line-height: 28px;
        padding-bottom: 0;

        @media all and ( orientation: landscape ) {
          font-size: var(--font-size-16);
          white-space: normal;
          padding-top: 20px;
        }

        .vehicle_register_title {
          margin-top: 20px;
          text-align: center;
          font-weight: var(--font-weight-bold);
        }
      }
      .btn_basic_primary{
        border-radius: 8px;
        &:active {
          background: var(--C-primary-600);
        }
        &:disabled {
          background-color: var(--C-gray-100);
          color: var(--C-gray-300);
        }
      }
      .form{
        &_single {
          display: block;
          color: var(--C-gray-500);
          font-size: var(--font-size-14);
          line-height: 2.4rem;
          word-break: break-all;
          padding: 0 12px 0 0;
          text-align: left;
        }
        &_checkbox{
          position: relative;
          padding-left: 32px;
          margin-bottom: 8px;

          &_single {
            position: relative;
            padding-left: 0;
          }
          &:last-child{
            margin-bottom: 0;
          }

          &_label {
            display: block;
            color: var(--C-gray-500);
            font-size: var(--font-size-14);
            line-height: 2.4rem;
            word-break: break-all;
            padding-right: 12px;
            text-align: left;

            &::before {
              @include position(calc(50% - 12px), null, null, 0);
              content: '';
              width: 24px;
              height: 24px;
              @include set-bg(icon_checkbox_sub_off);
            }

            &.checked{
              &::before {
                @include set-bg(icon_checkbox_sub_on);
              }
            }
          }
          &_link {
            @include position(calc(50% - 8px), 0);
            @include inBlind;
            width: 12px;
            height: 16px;
            @include set-bg(icon_arrow_right_gray_12);
          }
          &_all {
            display: block;
            position: relative;
            padding: 8px 0 8px 58px;
            border: 1px solid var(--C-gray-200);
            border-radius: 8px;
            margin-bottom: 18px;
            width: 100%;
            text-align: left;

            &:active {
              background-color: var(--C-gray-100);
            }

            .form_checkbox_label {
              font-size: var(--font-size-16);
              font-weight: var(--font-weight-bold);
              color: var(--C-gray-900);
              line-height: 3.8rem;

              &::before {
                width: 38px;
                height: 38px;
                @include position(calc(50% - 19px), 0, null, 10px);
                @include set-bg(icon_checkbox_off);
              }

              &.checked {
                &::before {
                  @include set-bg(icon_checkbox_on);
                }
              }
            }
          }
        }
      }
    }
  }
  &_guide {
    max-height: calc(100% - 96px) !important;

    &::before {
      @include position(0, 0, null, 0);
      content: '';
      height: 40px;
      background: linear-gradient(180deg, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    }
    .popup_cont {
      padding-top: 34px;
    }
    .popup_title {
      padding-top: 0;
      padding-bottom: 14px;
      line-height: 2.8rem;
      text-align: left;
      font-size: var(--font-size-20);
    }
    .popup_content {
      padding: 0 20px 34px;
    }
    .popup_btn_wrap {
      padding-top: 0;
    }
    .guide {
      &_desc {
        color: var(--C-gray-600);
        line-height: 2.4rem;
      }
      &_list {
        line-height: 2.4rem;

        &_tit {
          margin-top: 32px;
          color: var(--C-gray-900);
          font-weight: var(--font-weight-bold);
        }
        &_desc {
          margin-top: 8px;
          color: var(--C-gray-600);
        }
      }
    }
  }
  &_alarm {
    &_view {
      overflow: hidden;
      margin: 20px 20px 5px;
      border-radius: 10px 10px;

      &_img {
        width: 100%;
      }
    }
    .popup_btn_wrap {
      button {
        flex: 2;

        &:first-of-type {
          flex: 1;
        }
      }
    }
  }
  &_tip {
    margin-top: 12px;
    padding: 0 20px;

    &_tit {
      padding: 12px 0;
      line-height: 2.8rem;
      font-size: var(--font-size-20);
      font-weight: var(--font-weight-bold);
    }
    &_img {
      margin-top: 12px;
      text-align: center;

      img {
        width: 100%;
        max-width: 400px;
      }
    }
    &_desc {
      margin-top: 16px;
      color: var(--C-gray-500);
      line-height: 2.4rem;
    }
  }
  // 긴급호출
  &_emergency {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 430px;
    padding: 32px 20px 16px;
    box-sizing: border-box;
    text-align: center;

    &_title {
      color: var(--C-gray-700);
      line-height: 2.6rem;
      font-size: var(--font-size-18);
    }
    &_logo {
      margin-top: 32px;
    }
    &_insurer {
      margin-top: 8px;
      line-height: 3.6rem;
      font-size: var(--font-size-24);
      font-weight: var(--font-weight-bold);
    }
    &_modify {
      height: 40px;
      margin-top: 24px;
      padding: 0 16px;
      border: 1px solid var(--C-gray-400);
      border-radius: 8px;
      text-align: center !important;
    }
    &_call {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute !important;
      right: 20px;
      bottom: 16px;
      left: 20px;
      height: 52px;
      border-radius: 8px;
      background: var(--C-primary-500);
      color: var(--C-white);
      line-height: 52px;
      font-size: var(--font-size-18);
      font-weight: var(--font-weight-bold);

      &::before {
        @include set-bg(ico_phone);
        content: '';
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      &:disabled {
        background: var(--C-gray-100);
        color: var(--C-gray-400);

        &::before {
          mask: url("../../assets/images/ico_phone.svg") no-repeat 0 0 / 100% 100%;
          background-image: none;
          background-color: var(--C-gray-400);
        }
      }
    }
    &_ico {
      margin-top: 16px;
    }
    &_select {
      margin-top: 16px;
      line-height: 2.4rem;
      font-size: var(--font-size-18);
      font-weight: var(--font-weight-bold);
    }
    &_desc {
      margin-top: 8px;
      padding: 0 20px;
      color: var(--C-gray-500);
      line-height: 2.4rem;
      word-break: keep-all;
    }
  }
}

// body fix
.fix_scroll {
  overflow: hidden;
}
