@charset "UTF-8";

// mixins.scss

@mixin clear($content: '') {
  &:before,
  &:after {
    display: table;
    clear: both;
    content: $content;
  }

  &:after {
    clear: both;
  }
}

@mixin overflowHide {
  overflow-x: hidden;
  overflow-y: hidden;
}

@mixin overflowY {
  overflow-x: hidden;
  overflow-y: auto;
  //-webkit-overflow-scrolling: touch;
}

@mixin overflowX {
  overflow-x: auto;
  overflow-y: hidden;
  //-webkit-overflow-scrolling: touch;
}

// 기본 Tablets 설정값
@mixin gMobileTablet($width: 600px, $height: 450px) {
  @media screen and (min-width: $width) and (min-height: $height) {
    @content;
  }
}

// landscape Phablet ~ Pad
@mixin gPhablet($width: 640px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin g_invisible {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

@mixin contentElement($display: inline-block) {
  display: $display;
  content: '';
}

@mixin color-set($font-color, $background-color, $border-color) {
  color: $font-color;
  background-color: $background-color;
  border-color: $border-color;
}

@mixin ellipsis($display: inline-block, $max-width: 100%) {
  display: $display;
  overflow: hidden;
  max-width: $max-width;
  white-space: nowrap;
  word-break: normal;
  word-wrap: normal;
  text-overflow: ellipsis;
}

// strip unit
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
}

@mixin multiline-ellipsis(
  $lines: 2,
  $font-size: var(--font-size-16),
  $line-height: var(--base-line-height),
  $display: block
) {
  //@if unit($font-size) == 'rem' {
  //	$font-size-number : strip-unit($font-size) * strip-unit(var(--base-font-size)) !global;
  //} @else if unit($font-size) == 'px' {
  //	$font-size-number : strip-unit($font-size) !global;
  //}

  overflow: hidden;
  display: $display;
  display: -webkit-box;
  //max-height:$font-size-number * $line-height * $lines + px;
  max-height: none;
  max-height: fit-content;
  font-size: $font-size;
  text-overflow: ellipsis;
  line-height: $line-height;
  word-break: break-all;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

// @mixin bar($height: $M-bar-height, $background: $M-bar-color) {
// 	margin: -1px 7px 1px 0;
// 	display: inline-block;
// 	width: 1px;
// 	height: $height + px;
// 	background-color: $background;
// 	overflow: hidden;
// 	color: rgba(0,0,0,0);
// 	vertical-align: middle;
// }

@mixin position(
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $zindex: 10,
  $position: absolute
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  z-index: $zindex;
}

// Mobile Only: flex
// -------------------------
//flex
@mixin flex {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* OLD - IE 19- (buggy but mostly works) */
  display: flex; /* iOS 7.1+, android 4.4+ */
}

@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

@mixin flex-align($value) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
  //text-align: $value;
}

@mixin flex-justify($value) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-evenly {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-around;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}

@mixin flex-grow($int: 0, $shrink: 1, $basis: auto) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-negative: $shrink;
  -ms-flex: $int $shrink $basis;
  flex-shrink: $shrink;
}

@mixin flexGroup($value: h) {
  @include flex;

  @if $value == h {
    @include flex-direction(row);
    @include flex-justify(space-around);

    > div {
      @include flex;
      @include flex-align(center);
      @include flex-justify(center);
      @include flex-grow(1);
    }
  } @else if $value == v {
    @include flex-direction(column);
  }
}

@mixin opacity($value) {
  opacity: $value;
  filter: alpha(opacity=($value * 100));
}

// iphone X
@mixin iphoneSupport(
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $type: padding,
  $sign: 1
) {
  @if $top != null {
    #{$type}-top: $top;
    @supports (padding: unquote('max(0px)')) and (padding: constant(safe-area-inset-top)) {
      #{$type}-top: unquote('max(#{$top}, #{$sign}*constant(safe-area-inset-top))') !important;
    }
    @supports (padding: unquote('max(0px)')) and (padding: env(safe-area-inset-top)) {
      #{$type}-top: unquote('max(#{$top}, #{$sign}*env(safe-area-inset-top))') !important;
    }
  }
  @if $right != null {
    #{$type}-right: $right;
    @supports (padding: unquote('max(0px)')) and (padding: constant(safe-area-inset-right)) {
      #{$type}-right: unquote(
                      'max(#{$right}, #{$sign}*constant(safe-area-inset-right))'
      ) !important;
    }
    @supports (padding: unquote('max(0px)')) and (padding: env(safe-area-inset-right)) {
      #{$type}-right: unquote('max(#{$right}, #{$sign}*env(safe-area-inset-right))') !important;
    }
  }
  @if $bottom != null {
    #{$type}-bottom: $bottom;
    @supports (padding: unquote('max(0px)')) and (padding: constant(safe-area-inset-bottom)) {
      #{$type}-bottom: unquote(
                      'max(#{$bottom}, #{$sign}*constant(safe-area-inset-bottom))'
      ) !important;
    }
    @supports (padding: unquote('max(0px)')) and (padding: env(safe-area-inset-bottom)) {
      #{$type}-bottom: unquote('max(#{$bottom}, #{$sign}*env(safe-area-inset-bottom))') !important;
    }
  }
  @if $left != null {
    #{$type}-left: $left;
    @supports (padding: unquote('max(0px)')) and (padding: constant(safe-area-inset-left)) {
      #{$type}-left: unquote('max(#{$left}, #{$sign}*env(safe-area-inset-left))') !important;
    }
    @supports (padding: unquote('max(0px)')) and (padding: env(safe-area-inset-left)) {
      #{$type}-left: unquote('max(#{$left}, #{$sign}*env(safe-area-inset-left))') !important;
    }
  }
}

// 폰트 사이즈 rem 또는 px 사용 시 바로 변경
$defaultSize: 10px;
$defaultSize-tablet: 12px;

@function sizeSet($num) {
  @if $string != 'rem' {
    @return #{$num * $defaultSize}#{$string};
  } @else {
    @return #{$num}#{$string};
  }
}
//placeholder 스타일 지정
@mixin input-placeholder($color) {
  &.placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}
//그라데이션 (pc용)
@mixin linear-gradient($from, $to) {
  background-color: $to;
  background-image: -moz-linear-gradient(top, $from 0%, $to 100%);
  background-image: -webkit-gradient(
                  linear,
                  left top,
                  left bottom,
                  color-stop(0%, $from),
                  color-stop(100%, $to)
  );
  background-image: -webkit-linear-gradient(top, $from 0%, $to 100%);
  background-image: -o-linear-gradient(top, $from 0%, $to 100%);
  background-image: linear-gradient(to bottom, $from 0%, $to 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to});
}

@mixin gradient($direction, $list...) {
  background: nth(nth($list, 1), 1);
  background-image: -webkit-linear-gradient($direction, $list);
  background-image: -moz-linear-gradient($direction, $list);
  background-image: -o-linear-gradient($direction, $list);
  background-image: linear-gradient($direction, $list);
}

// svg 이미지
@mixin use-svg(
  $url,
  $width,
  $height,
  $offsets: 0,
  $display: inline-block,
  $type: left,
  $element: before,
  $position: absolute,
  $wrap-position: relative,
  $valign: middle,
  $bg: var(--C-tr),
  $repeat: no-repeat,
  $z-index: 10,
  $translate: null,
  $extension: svg,
  $padding: include
) {
  $base-url: '../../assets/images/';
  $offset: length($offsets);
  $top-offset: nth($offsets, 1);
  $right-offset: nth($offsets, 1);
  $left-offset: nth($offsets, 1);
  $bottom-offset: nth($offsets, 1);
  @if $offset == 4 {
    $right-offset: nth($offsets, 2);
    $bottom-offset: nth($offsets, 3);
    $left-offset: nth($offsets, 4);
  }
  @else if $offset == 3 {
    $right-offset: nth($offsets, 2);
    $bottom-offset: nth($offsets, 3);
    $left-offset: nth($offsets, 2);
  }
  @else if $offset == 2 {
    $right-offset: nth($offsets, 2);
    $left-offset: nth($offsets, 2);
  }
  display: $display;
  @if $wrap-position {
    position: $wrap-position;
  }

  @if $display == block and $type == top {
    padding-top: $top-offset + $height + $bottom-offset;
  }
  @if $display == inline-block {
    @if $position == absolute and $type != null {
      width: $width;
      height: $height;
      box-sizing: content-box;
      padding: $offsets;
    }
    @if $valign != null {
      vertical-align: $valign;
    }
  }
  &:#{$element} {
    @include overflowHide();
    @if $position == absolute and $type == right {
      @include position($top-offset, $right-offset, null, null, $z-index);
    }
    @else if $position == absolute {
      @include position($top-offset, null, null, $left-offset, $z-index);
    }
    @else if $position == relative {
      display: inline-block;
      margin: $offsets;
      vertical-align: $valign;
    }
    background-image: url($base-url + $url + '.' + $extension);
    background-repeat: $repeat;
    background-size: $width $height;
    background-position: 50% 50%;
    background-color: $bg;
    width: $width;
    height: $height;
    @if $display == block and $position == absolute {
      @if $translate != null {
        transform: translate(unquote('#{$translate}'));
      }
      @else if $type == top {
        transform: translate(-50%, 0);
      }
      @else if $type == center {
        transform: translate(-50%, -50%);
      } @else {
        transform: translate(0, -50%);
      }
    }
    content: '';
  }
}
@mixin use-svg-light($url, $element: before, $extension: svg) {
  $base-url: '../../assets/images/';
  &:#{$element} {
    background-image: url($base-url + $url + '.' + $extension);
  }
}

@mixin set-bg($url, $extension: svg, $position: 0 0, $size: 100% 100%) {
  $base-url: '../../assets/images/';
  background-image: url($base-url + $url + '.' + $extension);
  background-size: $size;
  background-position: $position;
  background-repeat: no-repeat;
}
@mixin use-svg-bg($url, $position: 0 0, $extension: svg) {
  $base-url: '../../assets/images/';
  background-image: url($base-url + $url + '.' + $extension);
  background-position: $position;
}

@mixin inBlind() {
  overflow: hidden;
  color: transparent;
  font-size: 0;
  text-indent: -300px;
}

@mixin set-overlay($pos: 'after') {
  @if ($pos == 'after') {
    &::after {
      @include position(0, 0, 0, 0, 100);
      content: '';
      border-radius: 4px;
      transition: background-color 100ms;
    }
  } @else {
    &::before {
      @include position(0, 0, 0, 0, 100);
      content: '';
      border-radius: 4px;
      transition: background-color 100ms;
    }
  }
  &:active {
    @if ($pos == 'after') {
      &::after {
        background-color: var(--C-wb-transparent-100);
      }
    } @else {
      &:before {
        background-color: var(--C-wb-transparent-100);
      }
    }
  }
}

// TMDS Typography
@mixin tmobi-font($size: 16, $bold: false, $lineHeight: 1.6) {
  @if $bold == true {
    font-weight: var(--font-weight-bold);
  }
  @if $bold == false {
    font-family: var(--font-weight-normal);
  }
  font-size: $size;
  line-height: $lineHeight + rem;
}

@mixin font-size-11() {
  @include tmobi-font(var(--font-size-11), false, 1.6);
}
@mixin font-size-11-bold() {
  @include tmobi-font(var(--font-size-11), true, 1.6);
}

@mixin font-size-12() {
  @include tmobi-font(var(--font-size-12), false, 1.8);
}
@mixin font-size-12-bold() {
  @include tmobi-font(var(--font-size-12), true, 1.8);
}

@mixin font-size-14() {
  @include tmobi-font(var(--font-size-14), false, 2.2);
}
@mixin font-size-14-bold() {
  @include tmobi-font(var(--font-size-14), true, 2.2);
}

@mixin font-size-16() {
  @include tmobi-font(var(--font-size-16), false, 2.6);
}
@mixin font-size-16-bold() {
  @include tmobi-font(var(--font-size-16), true, 2.6);
}

@mixin font-size-18() {
  @include tmobi-font(var(--font-size-18), false, 2.6);
}
@mixin font-size-18-bold() {
  @include tmobi-font(var(--font-size-18), true, 2.6);
}

@mixin font-size-20() {
  @include tmobi-font(var(--font-size-20), false, 3.0);
}
@mixin font-size-20-bold() {
  @include tmobi-font(var(--font-size-20), true, 3.0);
}

@mixin font-size-24() {
  @include tmobi-font(var(--font-size-24), false, 3.6);
}
@mixin font-size-24-bold() {
  @include tmobi-font(var(--font-size-24), true, 3.6);
}

@mixin property($variable: variable, $syntax: "<number>", $inherits: true, $initial-value: 0){
  @property --#{$variable} {
    syntax: $syntax;
    inherits: $inherits;
    initial-value: $initial-value;
  }
}
