@charset "UTF-8";

// $base- reset용 기본 변수입니다.

$base-font-family: TMOBI, sans-serif !default;
$base-font-weight: var(--font-weight-normal) !default;

:root {
  --G-contents: 100%;
  --G-contents-gap: 24px;
  --G-heights: 100%;
  --G-header-heights: 56px;
  --base-font-size: 10px;
  --base-line-height: 1.3;

  // Deprecated color
  --C-primary-100 : #F5F9FF;
  --C-primary-200: #e6f1ff;
  --C-primary-300: #cce0ff;
  --C-primary-400: #3d8bff;
  --C-primary-500: #0064ff;
  --C-primary-600: #0055D1;
  --C-carrot-event: #182553;
  --C-gray-100: #f8f9fa;
  --C-gray-150: #f4f5f6;
  --C-gray-200: #eef0f3;
  --C-gray-300: #e0e2e8;
  --C-gray-400: #bec5cc;
  --C-gray-500: #8c9299;
  --C-gray-600: #6e747a;
  // --C-gray-650 운전점수에서만 사용
  --C-gray-650: #646C7C;
  --C-gray-700: #51565c;
  // --C-gray-720 운전점수에서만 사용
  --C-gray-720: #474C58;
  // --C-gray-750 운전점수에서만 사용
  --C-gray-750: #414650;
  --C-gray-800: #36393d;
  --C-gray-900: #171819;
  --C-tmobi-green-100: #f1fbfb;
  --C-tmobi-green-200: #dff6f5;
  --C-tmobi-green-300: #beedec;
  --C-tmobi-green-400: #2dccc6;
  --C-tmobi-green-500: #00b2b2;
  --C-tmobi-green-600: #038591;
  --C-tmobi-pink-100: #fef7fc;
  --C-tmobi-pink-200: #ffe9f9;
  --C-tmobi-pink-300: #ffdbf4;
  --C-tmobi-pink-400: #ff70c8;
  --C-tmobi-pink-500: #fa32aa;
  --C-tmobi-pink-600: #c9388d;
  --C-tmobi-purple-100: #f9f7ff;
  --C-tmobi-purple-200: #f1ebfe;
  --C-tmobi-purple-300: #e3d8fe;
  --C-tmobi-purple-400: #976cfb;
  --C-tmobi-purple-500: #773efa;
  --C-tmobi-purple-600: #5b2cc7;
  --C-red-100: #FEF7F6;
  --C-red-200: #FDE8E4;
  --C-red-400: #FA5938;
  --C-red-500: #F24724;
  --C-red-600: #A63019;
  --C-orange-100: #FFFBF5;
  --C-orange-200: #FFF0D9;
  --C-orange-400: #FFAB3F;
  --C-orange-500: #FF9000;
  --C-orange-600: #B86E00;
  --C-yellow-100: #FFFDF6;
  --C-yellow-200: #FFF3D1;
  --C-yellow-400: #FFD26A;
  --C-yellow-500: #FFC219;
  --C-yellow-600: #D9A600;
  --C-green-100: #F3FCF6;
  --C-green-200: #DAF5EA;
  --C-green-400: #3FCB71;
  --C-green-500: #25B856;
  --C-green-600: #1B853E;
  --C-ev-blue-100: #f4fcfe;
  --C-ev-blue-200: #ddf5fb;
  --C-ev-blue-500: #00ace5;
  --C-ev-blue-600: #007fad;
  //basic color
  --C-tr: transparent; //투명
  --C-white: #ffffff; //흰색
  --C-black: #000000; //검정색
  //rgba (운전점수에서만 사용)
  --C-black-tr35: rgba(0, 0, 0, 0.35);
  //rgba
  --C-wb-transparent-100: rgba(0, 0, 0, 0.04);
  --C-wb-transparent-300: rgba(0, 0, 0, 0.1);
  --C-wb-transparent-400: rgba(0, 0, 0, 0.2);
  --C-wb-transparent-500: rgba(0, 0, 0, 0.3);
  --C-wb-transparent-600: rgba(0, 0, 0, 0.4);
  --C-wb-transparent-700: rgba(0, 0, 0, 0.6);
  --C-black-tr: rgba(0, 0, 0, 0);
  --C-white-tr70: rgba(255, 255, 255, 0.7);
  --C-white-tr30: rgba(255, 255, 255, 0.3);
  --C-red-tr08: rgba(223, 92, 92, 0.08);
  --Z-floating-navigation: 1900;
  --Z-header: 2000;
  --Z-full_popup: 2050;
  --Z-layer: 2100;
  --Z-loading-indicator: 2200;

  // CORE COLOR V1.0
  --wb-black: #000000;
  --wb-white: #ffffff;
  --gray-50: #F8F9FA;
  --gray-100: #EEF0F3;
  --gray-200: #E0E2E8;
  --gray-300: #BEC5CC;
  --gray-400: #8C9299;
  --gray-500: #6E747A;
  --gray-600: #51565C;
  --gray-700: #36393D;
  --gray-800: #27292D;
  --gray-900: #171819;
  --black-alpha-50: rgba(0, 0, 0, 0.04);
  --black-alpha-100: rgba(0, 0, 0, 0.08);
  --black-alpha-200: rgba(0, 0, 0, 0.14);
  --black-alpha-300: rgba(0, 0, 0, 0.2);
  --black-alpha-400: rgba(0, 0, 0, 0.3);
  --black-alpha-500: rgba(0, 0, 0, 0.45);
  --black-alpha-600: rgba(0, 0, 0, 0.6);
  --black-alpha-700: rgba(0, 0, 0, 0.7);
  --black-alpha-800: rgba(0, 0, 0, 0.8);
  --black-alpha-900: rgba(0, 0, 0, 0.9);
  --white-alpha-50: rgba(255, 255, 255, 0.05);
  --white-alpha-100: rgba(255, 255, 255, 0.1);
  --white-alpha-200: rgba(255, 255, 255, 0.2);
  --white-alpha-300: rgba(255, 255, 255, 0.3);
  --white-alpha-400: rgba(255, 255, 255, 0.4);
  --white-alpha-500: rgba(255, 255, 255, 0.5);
  --white-alpha-600: rgba(255, 255, 255, 0.6);
  --white-alpha-700: rgba(255, 255, 255, 0.7);
  --white-alpha-800: rgba(255, 255, 255, 0.8);
  --white-alpha-900: rgba(255, 255, 255, 0.9);
  --tmobi-blue-50: #F5F9FF;
  --tmobi-blue-100: #E6F1FF;
  --tmobi-blue-200: #CCE0FF;
  --tmobi-blue-300: #8AB9FF;
  --tmobi-blue-400: #3D8BFF;
  --tmobi-blue-500: #0064FF;
  --tmobi-blue-600: #0052D1;
  --tmobi-blue-700: #09429F;
  --tmobi-blue-800: #113063;
  --tmobi-blue-900: #15223D;
  --tmobi-pink-50: #FEF7FC;
  --tmobi-pink-100: #FFE9F9;
  --tmobi-pink-200: #FFD6F3;
  --tmobi-pink-300: #FFA3DE;
  --tmobi-pink-400: #FF61C2;
  --tmobi-pink-500: #FA32AA;
  --tmobi-pink-600: #D30D85;
  --tmobi-pink-700: #AD0B6C;
  --tmobi-pink-800: #7C084D;
  --tmobi-pink-900: #4C0530;
  --tmobi-purple-50: #F9F7FF;
  --tmobi-purple-100: #F1EBFE;
  --tmobi-purple-200: #E3D8FE;
  --tmobi-purple-300: #C3AAFD;
  --tmobi-purple-400: #976CFB;
  --tmobi-purple-500: #773EFA;
  --tmobi-purple-600: #5E2DCD;
  --tmobi-purple-700: #4D25A7;
  --tmobi-purple-800: #351E6C;
  --tmobi-purple-900: #221443;
  --tmobi-teal-50: #F1FBFB;
  --tmobi-teal-100: #DFF6F5;
  --tmobi-teal-200: #BFEDEC;
  --tmobi-teal-300: #7CDFDC;
  --tmobi-teal-400: #2DCDC7;
  --tmobi-teal-500: #00B2B2;
  --tmobi-teal-600: #009494;
  --tmobi-teal-700: #006B6B;
  --tmobi-teal-800: #02454B;
  --tmobi-teal-900: #012E32;
  --sky-50: #F4FCFE;
  --sky-100: #E5F7FC;
  --sky-200: #BFEDFC;
  --sky-300: #89DCFB;
  --sky-400: #43C7F9;
  --sky-500: #00ACE5;
  --sky-600: #008EC2;
  --sky-700: #00678A;
  --sky-800: #004157;
  --sky-900: #00374A;
  --green-50: #F7FDF9;
  --green-100: #E3F7EB;
  --green-200: #C3EFD3;
  --green-300: #82DEA3;
  --green-400: #3ECB71;
  --green-500: #25B856;
  --green-600: #1E9445;
  --green-700: #176E34;
  --green-800: #0E4420;
  --green-900: #082B14;
  --yellow-50: #FFFBF1;
  --yellow-100: #FFF4D6;
  --yellow-200: #FFE8AD;
  --yellow-300: #FFDA80;
  --yellow-400: #FFC94D;
  --yellow-500: #FFBD05;
  --yellow-600: #E0A500;
  --yellow-700: #A37800;
  --yellow-800: #664B00;
  --yellow-900: #3D2D00;
  --orange-50: #FFFBF5;
  --orange-100: #FFF1DB;
  --orange-200: #FFE1B8;
  --orange-300: #FFC880;
  --orange-400: #FFA633;
  --orange-500: #FF9000;
  --orange-600: #D17600;
  --orange-700: #985B01;
  --orange-800: #603901;
  --orange-900: #3D2300;
  --red-50: #FEF7F6;
  --red-100: #FDEBE7;
  --red-200: #FDDAD3;
  --red-300: #FDDAD3;
  --red-400: #F46548;
  --red-500: #F24724;
  --red-600: #CA2C0C;
  --red-700: #A3240B;
  --red-800: #6E1807;
  --red-900: #521205;

  // UI Variables V1.0
  --text-basic-default: var(--gray-900);
  --text-basic-description: var(--gray-600);
  --text-basic-caption: var(--gray-400);
  --text-basic-disabled: var(--gray-300);
  --text-basic-placeholder: var(--gray-300);
  --text-basic-inverse: var(--wb-white);
  --text-basic-link: var(--tmobi-blue-600);
  --text-basic-accent: var(--tmobi-blue-500);
  --text-basic-ev: var(--sky-500);
  --text-basic-oil: var(--tmobi-purple-500);
  --text-button-primary: var(--wb-white);
  --text-button-secondary: var(--gray-900);
  --text-button-accent: var(--tmobi-blue-500);
  --text-button-disabled: var(--gray-300);
  --text-button-disabled-stroke: var(--gray-200);
  --text-button-inverse: var(--wb-white);
  --text-success-default: var(--tmobi-blue-500);
  --text-success-bolder: var(--tmobi-blue-600);
  --text-warning-default: var(--orange-500);
  --text-warning-bolder: var(--orange-600);
  --text-danger-default: var(--red-500);
  --text-danger-bolder: var(--red-600);
  --background-surface-primary-base: var(--wb-white);
  --background-surface-primary-elevated: var(--wb-white);
  --background-surface-secondary-base: var(--gray-50);
  --background-surface-secondary-alt: var(--gray-100);
  --background-surface-tertiary-base: var(--gray-100);
  --background-surface-floating-onMap: var(--wb-white);
  --background-surface-pop-overlay: var(--black-alpha-600);
  --background-surface-ads: var(--wb-white);
  --background-surface-ads-opacity0: var(--wb-white);
  --background-surface-touch-target: var(--black-alpha-100);
  --background-button-primary: var(--tmobi-blue-500);
  --background-button-secondary: var(--gray-100);
  --background-button-negative: var(--red-500);
  --background-button-disabled: var(--gray-50);
  --background-button-inverse: var(--wb-white);
  --background-input-dafault: var(--wb-white);
  --background-input-disabled: var(--gray-50);
  --background-success-primary: var(--tmobi-blue-500);
  --background-success-secondary: var(--tmobi-blue-100);
  --background-success-bolder: var(--tmobi-blue-600);
  --background-success-disabled: var(--gray-200);
  --background-warning-primary: var(--orange-500);
  --background-warning-secondary: var(--orange-100);
  --background-warning-bolder: var(--orange-600);
  --background-warning-disabled: var(--gray-200);
  --background-danger-primary: var(--red-500);
  --background-danger-secondary: var(--red-100);
  --background-danger-bolder: var(--red-600);
  --background-danger-disabled: var(--gray-200);
  --border-line-default: var(--gray-100);
  --border-line-default-elevated: var(--gray-100);
  --border-line-wide: var(--gray-50);
  --border-line-wide-elevated: var(--gray-50);
  --border-input-stroke-default: var(--gray-200);
  --border-input-stroke-accent: var(--gray-900);
  --border-input-stroke-danger: var(--red-500);
  --border-button-stroke-default: var(--gray-200);
  --border-button-stroke-accent: var(--tmobi-blue-500);
  --border-button-stroke-disabled: var(--gray-100);
  --tmobi-brand-primary: var(--tmobi-blue-500);
  --tmobi-brand-secondary: var(--tmobi-teal-500);
  --tmobi-brand-ev: var(--sky-500);
  --tmobi-brand-oil: var(--tmobi-purple-500);
  --tmobi-brand-benefit: var(--tmobi-pink-500);
  --tmobi-traffic-congestion: var(--red-500);
  --tmobi-traffic-delay: var(--yellow-500);
  --tmobi-traffic-smooth: var(--green-500);
  --icon-primary: var(--gray-900);
  --icon-secondary: var(--gray-700);
  --icon-tertiary: var(--gray-500);
  --icon-quaternary: var(--gray-300);
  --icon-accent: var(--tmobi-blue-500);
  --icon-disabled: var(--gray-200);
  --icon-inverse: var(--wb-white);
  --icon-on-cta: var(--wb-white);
  --palette-wb-0: var(--wb-white);
  --palette-wb-1000: var(--wb-black);
  --palette-gray-lightest: var(--gray-50);
  --palette-gray-lighter: var(--gray-100);
  --palette-gray-light: var(--gray-200);
  --palette-gray-medium: var(--gray-300);
  --palette-gray-neutral: var(--gray-400);
  --palette-gray-primary: var(--gray-500);
  --palette-gray-bold: var(--gray-600);
  --palette-gray-strong: var(--gray-700);
  --palette-gray-darker: var(--gray-800);
  --palette-gray-darkest: var(--gray-900);
  --palette-tmobiblue-lightest: var(--tmobi-blue-50);
  --palette-tmobiblue-lighter: var(--tmobi-blue-100);
  --palette-tmobiblue-light: var(--tmobi-blue-200);
  --palette-tmobiblue-medium: var(--tmobi-blue-300);
  --palette-tmobiblue-neutral: var(--tmobi-blue-400);
  --palette-tmobiblue-primary: var(--tmobi-blue-500);
  --palette-tmobiblue-bold: var(--tmobi-blue-600);
  --palette-tmobiblue-strong: var(--tmobi-blue-700);
  --palette-tmobiblue-darker: var(--tmobi-blue-800);
  --palette-tmobiblue-darkest: var(--tmobi-blue-900);
  --palette-tmobipink-lightest: var(--tmobi-pink-50);
  --palette-tmobipink-lighter: var(--tmobi-pink-100);
  --palette-tmobipink-light: var(--tmobi-pink-200);
  --palette-tmobipink-medium: var(--tmobi-pink-300);
  --palette-tmobipink-neutral: var(--tmobi-pink-400);
  --palette-tmobipink-primary: var(--tmobi-pink-500);
  --palette-tmobipink-bold: var(--tmobi-pink-600);
  --palette-tmobipink-strong: var(--tmobi-pink-700);
  --palette-tmobipink-darker: var(--tmobi-pink-800);
  --palette-tmobipink-darkest: var(--tmobi-pink-900);
  --palette-tmobipurple-lightest: var(--tmobi-purple-50);
  --palette-tmobipurple-lighter: var(--tmobi-purple-100);
  --palette-tmobipurple-light: var(--tmobi-purple-200);
  --palette-tmobipurple-medium: var(--tmobi-purple-300);
  --palette-tmobipurple-neutral: var(--tmobi-purple-400);
  --palette-tmobipurple-primary: var(--tmobi-purple-500);
  --palette-tmobipurple-bold: var(--tmobi-purple-600);
  --palette-tmobipurple-strong: var(--tmobi-purple-700);
  --palette-tmobipurple-darker: var(--tmobi-purple-800);
  --palette-tmobipurple-darkest: var(--tmobi-purple-900);
  --palette-tmobiteal-lightest: var(--tmobi-teal-50);
  --palette-tmobiteal-lighter: var(--tmobi-teal-100);
  --palette-tmobiteal-light: var(--tmobi-teal-200);
  --palette-tmobiteal-medium: var(--tmobi-teal-300);
  --palette-tmobiteal-neutral: var(--tmobi-teal-400);
  --palette-tmobiteal-primary: var(--tmobi-teal-500);
  --palette-tmobiteal-bold: var(--tmobi-teal-600);
  --palette-tmobiteal-strong: var(--tmobi-teal-700);
  --palette-tmobiteal-darker: var(--tmobi-teal-800);
  --palette-tmobiteal-darkest: var(--tmobi-teal-900);
  --palette-sky-lightest: var(--sky-50);
  --palette-sky-lighter: var(--sky-100);
  --palette-sky-light: var(--sky-200);
  --palette-sky-medium: var(--sky-300);
  --palette-sky-neutral: var(--sky-400);
  --palette-sky-primary: var(--sky-500);
  --palette-sky-bold: var(--sky-600);
  --palette-sky-strong: var(--sky-700);
  --palette-sky-darker: var(--sky-800);
  --palette-sky-darkest: var(--sky-900);
  --palette-green-lightest: var(--green-50);
  --palette-green-lighter: var(--green-100);
  --palette-green-light: var(--green-200);
  --palette-green-medium: var(--green-300);
  --palette-green-neutral: var(--green-400);
  --palette-green-primary: var(--green-500);
  --palette-green-bold: var(--green-600);
  --palette-green-strong: var(--green-700);
  --palette-green-darker: var(--green-800);
  --palette-green-darkest: var(--green-900);
  --palette-yellow-lightest: var(--yellow-50);
  --palette-yellow-lighter: var(--yellow-100);
  --palette-yellow-light: var(--yellow-200);
  --palette-yellow-medium: var(--yellow-300);
  --palette-yellow-neutral: var(--yellow-400);
  --palette-yellow-primary: var(--yellow-500);
  --palette-yellow-bold: var(--yellow-600);
  --palette-yellow-strong: var(--yellow-700);
  --palette-yellow-darker: var(--yellow-800);
  --palette-yellow-darkest: var(--yellow-900);
  --palette-orange-lightest: var(--orange-50);
  --palette-orange-lighter: var(--orange-100);
  --palette-orange-light: var(--orange-200);
  --palette-orange-medium: var(--orange-300);
  --palette-orange-neutral: var(--orange-400);
  --palette-orange-primary: var(--orange-500);
  --palette-orange-bold: var(--orange-600);
  --palette-orange-strong: var(--orange-700);
  --palette-orange-darker: var(--orange-800);
  --palette-orange-darkest: var(--orange-900);
  --palette-red-lightest: var(--red-50);
  --palette-red-lighter: var(--red-100);
  --palette-red-light: var(--red-200);
  --palette-red-medium: var(--red-300);
  --palette-red-neutral: var(--red-400);
  --palette-red-primary: var(--red-500);
  --palette-red-bold: var(--red-600);
  --palette-red-strong: var(--red-700);
  --palette-red-darker: var(--red-800);
  --palette-red-darkest: var(--red-900);
  --palette-blackalpha-veryfaint: var(--black-alpha-50);
  --palette-blackalpha-faint: var(--black-alpha-100);
  --palette-blackalpha-mild: var(--black-alpha-200);
  --palette-blackalpha-gentle: var(--black-alpha-300);
  --palette-blackalpha-middensity: var(--black-alpha-400);
  --palette-blackalpha-half: var(--black-alpha-500);
  --palette-blackalpha-bolddensity: var(--black-alpha-600);
  --palette-blackalpha-high: var(--black-alpha-700);
  --palette-blackalpha-dense: var(--black-alpha-800);
  --palette-blackalpha-fully: var(--black-alpha-900);
  --palette-whitealpha-veryfaint: var(--white-alpha-50);
  --palette-whitealpha-faint: var(--white-alpha-100);
  --palette-whitealpha-mild: var(--white-alpha-200);
  --palette-whitealpha-gentle: var(--white-alpha-300);
  --palette-whitealpha-middensity: var(--white-alpha-400);
  --palette-whitealpha-half: var(--white-alpha-500);
  --palette-whitealpha-bolddensity: var(--white-alpha-600);
  --palette-whitealpha-high: var(--white-alpha-700);
  --palette-whitealpha-dense: var(--white-alpha-800);
  --palette-whitealpha-fully: var(--white-alpha-900);

  // font size
  --font-size-11: 1.1rem;
  --font-size-12: 1.2rem;
  --font-size-13: 1.3rem;
  --font-size-14: 1.4rem;
  --font-size-15: 1.5rem;
  --font-size-16: 1.6rem;
  --font-size-17: 1.7rem;
  --font-size-18: 1.8rem;
  --font-size-20: 2.0rem;
  --font-size-24: 2.4rem;

  // font weight
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-black: 900;
}
