.loading {
  &_indicator {
    &.type_show {
      background: var(--C-black-tr35);
    }
    @extend %flexbox-v;
    z-index: var(--Z-loading-indicator);
    position: fixed;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
  }
  &_longtime_icon {
    border-radius: 52px;
    background-color: #fff;
    border: 12px solid var(--C-white);
  }
  &_message {
    padding-top: 40px;
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-black);
    color: var(--C-white);
    line-height: 28px;
    text-align:center;
  }
  &_desc {
    padding-top: 16px;
    color: var(--C-white)-tr70;
    font-size: var(--font-size-16);
  }
}
