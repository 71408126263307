@import '../../basics/mixins';

.wrap {
  overflow: hidden;
  padding: 16px 64px 20px 20px;
  box-sizing: border-box;

  &.is_icon {
    min-height: 126px;
  }
}
.title {
  line-height: 2.4rem;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-bold);
  white-space: pre-wrap;
}
.link {
  margin-top: 24px;
}
.img {
  @include position(null, 16px, 16px);
}
.close {
  @include position(16px, 16px);
  @include use-svg-bg(ico_exit);
  position: absolute !important;
  overflow: hidden;
  width: 18px;
  height: 18px;
  background-size: 18px;
}
