@charset "UTF-8";

.g_invisible,
.blind {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

/* skip navigation */
.g_skip {
  @include g_invisible;
}
.g_btn_skip {
  position: absolute;
  left: 0;
  top: -30px;
  width: 128px;
  background-color: #191919;
  border: 1px solid var(--C-gray-300);
  text-align: center;
  outline: none;
}
.g_btn_skip:active,
.g_btn_skip:focus {
  top: 2px;
  text-decoration: none;
  cursor: pointer;
  zoom: 1;
}
.g_btn_skip_inner {
  display: inline-block;
  padding-top: 2px;
  font-size: 12px;
  letter-spacing: -1px;
  color: #fff;
  line-height: 26px;
}
.float_l {
  float: left;
}
.float_r {
  float: right;
}

/* line-height*/
.lh-20 {
  line-height: 20px;
}
.lh-24 {
  line-height: 24px;
}
.lh-28 {
  line-height: 28px;
}
.lh-36 {
  line-height: 36px;
}

/* 페이지 그리드 */
.col-md-1 {
  width: 8.33333333%;
}
.col-md-2 {
  width: 16.66666667%;
}
.col-md-3 {
  width: 25%;
}
.col-md-4 {
  width: 33.33333333%;
}
.col-md-5 {
  width: 41.66666667%;
}
.col-md-6 {
  width: 50%;
}
.col-md-7 {
  width: 58.333333%;
}
.col-md-8 {
  width: 66.66666667%;
}
.col-md-9 {
  width: 75%;
}
.col-md-10 {
  width: 83.33333333%;
}
.col-md-11 {
  width: 91.66666667%;
}
.col-md-12,
.width100 {
  width: 100%;
}

.mt0 {
  margin-top: 0 !important;
}
.mt4 {
  margin-top: 4px !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt24 {
  margin-top: 24px !important;
}
.mt25 {
  margin-top: 25px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt32 {
  margin-top: 32px !important;
}
.mt35 {
  margin-top: 35px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt48 {
  margin-top: 48px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt60 {
  margin-top: 60px !important;
}
.mt70 {
  margin-top: 70px !important;
}
.mt100 {
  margin-top: 100px !important;
}
.mmt5 {
  margin-top: -5px !important;
}
.mmt10 {
  margin-top: -10px !important;
}
.mmt15 {
  margin-top: -15px !important;
}
.mmt20 {
  margin-top: -20px !important;
}
.mmt25 {
  margin-top: -25px !important;
}
.mmt30 {
  margin-top: -30px !important;
}
.mmt48 {
  margin-top: -48px !important;
}
.mr4 {
  margin-right: 4px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr8 {
  margin-right: 8px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mr15 {
  margin-right: 15px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mr30 {
  margin-right: 30px !important;
}
.ml4 {
  margin-left: 4px !important;
}
.ml5 {
  margin-left: 5px !important;
}
.ml8 {
  margin-left: 8px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml13 {
  margin-left: 13px !important;
}
.ml15 {
  margin-left: 15px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.ml40 {
  margin-left: 40px !important;
}
.ml70 {
  margin-left: 70px !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mb8 {
  margin-bottom: 8px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb16 {
  margin-bottom: 16px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb25 {
  margin-bottom: 25px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb60 {
  margin-bottom: 60px !important;
}
.pt40 {
  padding-top: 40px;
}
.pb40 {
  padding-bottom: 40px;
}
.pr20 {
  padding-right: 20px;
}
.pr40 {
  padding-right: 40px;
}
.w400 {
  width: 400px !important;
}
.wp30 {
  width: 30% !important;
}
.wp33 {
  width: 33% !important;
}
.wp35 {
  width: 35% !important;
}
.wp40 {
  width: 40% !important;
}
.wp45 {
  width: 45% !important;
}
.wp50 {
  width: 50% !important;
}
.wp55 {
  width: 55% !important;
}
.wp60 {
  width: 60% !important;
}
.wp70 {
  width: 70% !important;
}
.type {
  //align
  &_center {
    text-align: center !important;
  }
  &_left {
    text-align: left !important;
  }
  &_right {
    text-align: right !important;
  }
  &_bottom {
    @include flex-align(flex-end);
  }
  &_auto {
    width: auto;
    margin: 0 auto;
  }
  // flex
  &_fix-v {
    @extend %flexbox-fix-v;
  }

  // layout
  &_abs {
    position: absolute;
    z-index: 20;
  }

  // focus
  &_focus {
    position: initial;
    transform: translateY(0);
  }

  // button
  &_on {
    opacity: 1;
  }
  &_off {
    opacity: 0.0001;
    pointer-events: none;
  }

  // text
  &_nowrap {
    white-space: nowrap;
  }
  &_word {
    word-break: keep-all;
    word-wrap: break-word;
  }
  &_mark {
    font-weight: var(--font-weight-bold);
    font-style: normal;
    color: var(--C-red-500);
  }
  &_primary {
    color: var(--C-primary-500);
  }
  &_point {
    font-style: normal;
    color: var(--C-red-500);
  }
  &_red {
    font-style: normal;
    color: var(--C-red-500);
  }
  &_blue {
    font-style: normal;
    color: var(--C-primary-600);
  }
  &_black {
    font-style: normal;
    color: var(--C-black);
  }
  &_green {
    font-style: normal;
    color: var(--C-green-200);
  }
  &_dim {
    color: var(--C-gray-300);
  }
  &_highlight {
    &_hana {
      color: var(--C-tmobi-green-600);
    }
  }
  &_default {
    font-size: var(--font-size-16);
  }
  &_under {
    font-size: 1.5rem;
  }
  &_big {
    font-size: var(--font-size-18);
  }
  &_xbig {
    font-size: var(--font-size-24);
  }
  &_xsbig {
    font-size: var(--font-size-20);
  }
  &_normal {
    font-size: var(--font-size-14);
  }
  &_small {
    font-size: 1.3rem;
  }
  &_xsmall {
    font-size: var(--font-size-12);
  }
  &_mini {
    font-size: 1.0rem;
  }
  &_small {
    font-size: 1.3rem;
  }
  &_medium {
    font-weight: var(--font-weight-bold);
  }
  &_bold {
    font-weight: var(--font-weight-black);
  }
  &_base {
    font-weight: var(--font-weight-normal);
  }
  &_after {
    &:after {
      @include position(0,0,0,0,100);
      content: '';
    }
  }

  // display
  &_flex {
    @extend %flexbox;
  }
  &_flex_between {
    @extend %flex-between
  }
  &_flex_v {
    @extend %flexbox-stretch-v;
    text-align: left;
  }
  &_flex_between {
    @extend %flex-between
  }
  &_block {
    display: block;
  }
  &_ib {
    display: inline-block;
  }
  &_none {
    display: none !important;
  }
  &_visible {
    opacity: 1;
  }
  &_disable {
    .content {
      overflow: hidden;
      pointer-events: none;
    }
  }

  // style
  &_flat {
    box-shadow: none !important;
    animation: none !important;
  }
  &_effect {
    &:active {
      @extend %card-touch-in;
    }
  }
  &_touch {
    animation: card-touch-in ease-out 0.12s forwards !important;
  }
  &_bar {
    &:not(:first-child) {
      &:before {
        @include position(4px, null, null, 0);
        width: 1px;
        height: 12px;
        background-color: var(--C-gray-200);
        content: '';
      }
      margin-left: 8px;
      padding-left: 9px;
    }
    position: relative;
    vertical-align: inherit;
  }

  // loading
  // by @pio: loading 되살림
  &_loading {
    @include use-svg('../../assets/images/' + circular, 44px, 44px, 50%, block, center, $wrap-position: null);
    &:before {
      margin: -22px 0 0 -22px;
      animation: loading 2s infinite linear;
    }
    &.type-light {
      background-color: var(--C-white) !important;
    }
    background-color: var(--C-wb-transparent-700);
    color: transparent;
    transition-delay: 0.3s;
  }

  // event
  &_prevent-event {
    pointer-events: none;
  }

  &_preserve-wrap {
    white-space: pre-wrap;
    word-break: normal;
  }

  &_break_all {
    word-break: break-all;
  }

  // slide
  &_only.swiper-slide {
    width: calc(100% - 40px);
  }
  &_multi.swiper-slide {
    width: calc(100% - 60px);
  }
}

.landscape {
  &_break {
    @media all and ( orientation: landscape ) and ( min-width: 420px ) {
      display: inline-block;
    }
  }
  &_hidden {
    @media all and ( orientation: landscape ) {
      display: none;
    }
  }
}

.portrait {
  &_hidden {
    @media all and (orientation: portrait) {
      display: none;
    }
  }
}

.loading_section {
  @include position(0, 0, 0, 0, 100);
  @include gradient(to right, var(--C-gray-100) 40%, var(--C-gray-200) 50%, var(--C-gray-100) 60%);
  background-position: 110% 0;
  background-size: 250% 100%;
  animation: loading_gradation 0.85s cubic-bezier(0.2, 0.2, 1, 0.8) infinite;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes loading_gradation {
  0% {
    background-position: 110% 0;
  }
  50% {
    background-position: 50% 0;
  }
  100% {
    background-position: 0 0;
  }
}
@keyframes loading_gradation {
  0% {
    background-position: 110% 0;
  }
  50% {
    background-position: 50% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform:translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
  100% {
    -webkit-transform:translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -4px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeOut {
  0% {
    -webkit-transform:translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -4px, 0);
  }
}


@keyframes bounce {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(3px); }
  100% { transform: translateY(0); }
}

.overflow_hidden,
.fix_scroll {
  overflow: hidden;
}

// Intersection Observer target
.intersection_observer_target {
  height: 1px;
}
