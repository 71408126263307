.insure_loading_indicator {
  @include flex;
  @include flex-direction(column);
  @include flex-justify(center);
  @include flex-align(center);
  @include position(0, 0, 0, 0, var(--Z-loading-indicator), fixed);
  background-color: var(--C-wb-transparent-700);
  transition: background-color 0.2s ease;

  &.type-hide {
    visibility: hidden;
    background-color: var(--C-black-tr);
  }

  &_lottie {
    width: 80px;
    height: 80px;
  }

  &_title {
    font-weight: var(--font-weight-bold);
    color: var(--C-white);
    font-size: var(--font-size-16);
    margin-top: 20px;
    text-align: center;
  }
}
