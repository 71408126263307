.wrap {
  position: relative;
  margin: 16px 0;
  border-radius: 12px;
  background: var(--wb-white);
}
.title {
  padding: 20px 16px 0;
  color: var(--text-basic-default);
  line-height: 2.8rem;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-bold);
}
.description {
  padding: 4px 16px 0;
  color: var(--text-basic-description);
  line-height: 2.1rem;
  font-size: var(--font-size-13);
}
