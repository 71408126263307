.benefit {
  // 카테고리 설명
  &_category {
    &_desc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 16px;
      padding: 12px 16px;
      border-radius: 8px;
      background: var(--C-gray-100);
      color: var(--C-gray-700);
      line-height: 2rem;
      font-size: var(--font-size-14);
    }
  }
  // 카테고리별 리스트 영역
  &_panel {
    border-top: 8px solid var(--C-gray-100);
    padding-top: 20px;

    // 메인 페이지에서 보여지는 형태
    .main & {
      border: 0;
      padding: 0;
      margin: -12px 0;
    }
    &:first-of-type {
      border: 0;
      padding: 0;
    }
    &_header {
      padding: 16px;

      // 메인 페이지에서 보여지는 형태
      .main & {
        @include set-overlay;
        position: relative;
        padding: 16px 20px;

        &::before {
          @include position(50%, 16px);
          @include set-bg(ico_12_right);
          transform: translateY(-50%);
          content: '';
          width: 9px;
          height: 12px;
        }
        &_tit {
          .new {
            display: none;
          }
        }
        &_desc {
          margin-top: 4px;
        }
      }
    }
    &_tit {
      display: flex;
      align-items: center;
      color: var(--C-black);
      line-height: 2.8rem;
      font-size: 2rem;
      font-weight: var(--font-weight-bold);

      .new {
        content: '';
        width: 35px;
        height: 20px;
        margin-left: 8px;
        border-radius: 10px;
        color: var(--C-red-500);
        line-height: 2rem;
        text-align: center;
        font-size: var(--font-size-11);
        font-weight: var(--font-weight-bold);
        background-color: var(--C-red-200);
      }
    }
    &_desc {
      margin-top: 8px;
      color: var(--C-gray-500);
      line-height: 2rem;
      font-size: var(--font-size-14);
    }
  }
  // list
  &_list {
    margin-top: 20px;
    padding-bottom: 20px;

    // 커머스 가로 스와이프형, 메인 탭 형식 커머스
    &.type_commerce_carousel,
    &.type_commerce_carousel_main_only {
      display: flex;
      overflow-x: auto;
      padding: 0 10px 20px;

      // 카드안에 혜택이 있는 경우 예외처리
      &.exception {
        padding-left: 14px;
        padding-right: 14px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    // 카드형
    &.type_card {
      margin-top: 24px;

      // 메인, 인기혜택 페이지 배너 형태의 카드형 예외처리(TMAPWEB-2442)
      &.exception {
        margin-top: 12px;
        margin-bottom: 12px;
        padding-bottom: 0;
      }
    }
    &_item {
      display: flex;
      position: relative;
      padding: 16px 24px 16px 16px;

      // 메인, 인기혜택 페이지 배너 형태의 카드형 예외처리(TMAPWEB-2442)
      .type_card.exception & {
        width: calc(100% - 32px) !important;
        margin: 12px 16px;
        border-radius: 12px;
      }
      // 카드안에 혜택이 있는 경우 예외처리(목록형)
      .type_list.exception & {
        padding-left: 20px;
        padding-right: 28px;

        &::before {
          right: 20px;
        }
      }
      // 카드안에 혜택이 있는 경우 예외처리(커머스 목록형)
      .type_commerce_list.exception & {
        padding-left: 20px;
        padding-right: 20px;
      }
      // 커머스 목록형, 커머스 가로 스와이프형, 메인 탭 형식 커머스
      .type_commerce_list &,
      .type_commerce_carousel &,
      .type_commerce_carousel_main_only & {
        padding: 8px 16px;

        &::before {
          display: none;
        }
        &_img {
          overflow: hidden;
          width: 96px;
          height: 96px;
          border-radius: 8px;
        }
        &_tit {
          color: var(--C-gray-700);
          line-height: 2rem;
          font-size: var(--font-size-14);
          font-weight: var(--font-weight-normal);
        }
        &_desc {
          color: var(--C-gray-500);
          line-height: 1.6rem;
          font-size: var(--font-size-11);
          font-weight: var(--font-weight-normal);
        }
        &_condition {
          margin-top: 4px;
          color: var(--C-gray-900);
          line-height: 2.4rem;
          font-size: var(--font-size-16);
          font-weight: var(--font-weight-bold);
        }
        &_additional {
          margin-top: 0;
          color: var(--C-gray-500);
          line-height: 1.6rem;
          font-size: var(--font-size-11);
        }
      }
      // 커머스 가로 스와이프형,
      // 메인 탭 형식 커머스
      .type_commerce_carousel &,
      .type_commerce_carousel_main_only & {
        display: block;
        width: 148px;
        padding: 0 6px;

        &_img {
          width: 136px;
          height: 136px;
        }
        &_tit {
          margin-top: 12px;
        }
      }
      // 메인 탭 형식 커머스 스와이프형
      .type_commerce_carousel_main_only & {
        &_tit {
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 2.2rem;
          white-space: pre-wrap;
        }
        &_condition {
          white-space: pre-wrap;
        }
      }
      // 카드형
      .type_card & {
        overflow: hidden;
        justify-content: center;
        width: calc(100% - 40px) !important;
        margin: 16px 20px;
        padding: 0;
        border: 1px solid var(--gray-100);
        border-radius: 16px;
        text-align: center;

        &::before {
          display: none;
        }
        &_img {
          width: 100%;
          max-width: 480px;
          height: auto;
          margin: 0;
        }
      }
      // 기본(목록형)
      &::before {
        @include position(0, 16px, 0);
        @include set-bg(ico_12_right);
        content: '';
        width: 8px;
        height: 12px;
        margin: auto 0;
      }
      &_img {
        width: 48px;
        height: 48px;
        margin-right: 16px;
      }
      &_info {
        flex: 1;
      }
      &_name {
        display: block;
        margin-bottom: 4px;
        color: var(--C-gray-600);
        line-height: 1.6rem;
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-bold);
      }
      &_tit {
        display: block;
        line-height: 2.4rem;
        font-weight: var(--font-weight-bold);
      }
      &_desc {
        margin-top: 4px;
        color: var(--C-gray-600);
        line-height: 2rem;
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-bold);
      }
      &_condition {
        margin-top: 4px;
        color: var(--C-gray-500);
        line-height: 1.6rem;
        font-size: var(--font-size-11);
      }
      &_point {
        color: var(--C-primary-500);
      }
      &_additional {
        margin-top: 4px;
        color: var(--C-gray-300);
        line-height: 1.4rem;
        font-size: 0.8rem;
      }
    }
  }
  // 인기혜택
  &_popular {
    padding-bottom: 45px;

    &_ad {
      margin: 16px;
      text-align: center !important;

      &_btn {
        overflow: hidden;
        border-radius: 12px;
      }
      &_img {
        width: 100%;
        max-width: 400px;
      }
    }
    &_alarm {
      position: relative;
      margin: 16px;
      min-height: 52px;

      &_tit {
        @include flex;
        @include flex-align(center);
        position: relative;
        padding: 16px 50px 16px 44px;
        border-radius: 12px;
        background: var(--C-orange-200);
        font-weight: var(--font-weight-bold);
        word-break: keep-all;

        &::before {
          @include position(16px, null, null, 16px);
          @include set-bg(icon_alarm);
          content: '';
          display: block;
          width: 20px;
          height: 20px;
        }
      }
      &_switch {
        @include position(16px, 16px, null, null, 100);
      }
    }
    &_category {
      display: grid;
      gap: 6px;
      margin: 16px;

      &_cell {
        position: relative;
        max-width: 100%;
        width: 100%;

        &:first-of-type {
          grid-column: 1 / span 2;

          .benefit_popular_category_item {
            padding-right: 74px;
          }
          .benefit_popular_category_ico {
            top: 0;
            bottom: 0;
            right: 16px;
            width: 44px;
            height: 44px;
            margin: auto 0;
          }
        }
      }
      &_item {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        padding: 16px 20px 18px;
        border: 1px solid var(--C-gray-200);
        border-radius: 12px;
        box-sizing: border-box;
        background: var(--C-white);
      }
      &_tit {
        @include multiline-ellipsis(1);
        padding-right: 48px;
        line-height: 2.4rem;
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-bold);
      }
      &_desc {
        margin-top: 6px;
        color: var(--C-gray-500);
        line-height: 1.8rem;
        font-size: var(--font-size-12);
        word-break: keep-all;
      }
      &_tooltip {
        @include position(-7px, -1px);
        height: 19px;
        padding: 0 5px;
        border: 1px solid var(--C-tmobi-purple-500);
        border-radius: 10px;
        box-sizing: border-box;
        background: var(--C-white);
        color: var(--C-tmobi-purple-500);
        line-height: 17px;
        font-weight: var(--font-weight-bold);
        font-size: 1.0rem;
      }
      &_ico {
        @include position(12px, 12px);
        width: 32px;
        height: 32px;
      }
    }
  }

  // 개인화 정보
  &_personalized {
    // 혜택페이지 자동차보험 카테고리에서 예외 처리
    & + .benefit_panel {
      border: 0;
      padding-top: 0;

      .benefit_list {
        margin-top: 0;
      }
    }
    &_tray {
      margin: 16px 20px 0;
      border-radius: 8px;
      background: var(--background-surface-secondary-base);
    }
    &_info {
      padding: 16px 0;
    }
    &_reports {
      display: flex;
      justify-content: center;
      gap: 0 16px;
    }
    &_chart {
      position: relative;

      &_score {
        overflow: hidden;
        position: absolute;
        top: 28px;
        right: 0;
        left: 0;
        text-align: center;
        line-height: 3.6rem;
        letter-spacing: -1px;
        font-size: 2.8rem;
        font-weight: var(--font-weight-black);
      }
      &_unit {
        font-size: var(--font-size-13);
        font-weight: var(--font-weight-bold);
      }
    }
    &_distance {
      margin-top: 17px;

      &_item {
        display: flex;
        text-align: right;
        line-height: 2rem;
        font-size: var(--font-size-14);

        &:first-of-type {
          margin-bottom: 5px;
        }
      }
      &_label {
        width: 80px;
        text-align: left;
        color: var(--text-basic-description);
      }
      &_mileage {
        white-space: nowrap;
        font-weight: var(--font-weight-bold);
      }
    }
    &_desc {
      margin-top: 4px;
      color: var(--text-basic-description);
      text-align: center;
      line-height: 1.8rem;
      font-size: var(--font-size-13);
    }
    &_none {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px 0 24px;

      &_desc {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 13px;
      }
      &_start {
        width: auto !important;
        height: 32px;
        margin-top: -4px;
        padding: 0 10px;
        border-radius: 4px;
        border: 1px solid var(--border-button-stroke-default);
        background: var(--wb-white);
        color: var(--text-basic-description);
        font-size: var(--font-size-13);
      }
    }
  }
  &_filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 52px;
    padding: 0 20px;
    border-bottom: 1px solid var(--border-line-default);
    color: var(--font-size-14);
  }
}

// 보험상품 상세
.insurance {
  &_detail {
    padding-bottom: 106px;

    &_header {
      padding-bottom: 24px;
      background: var(--C-white);
      text-align: center;

      &_discount {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 200px;
        height: 160px;
        margin: 0 auto;
        box-sizing: border-box;
      }
      &_inner {
        mask: url('../../assets/images/bg_star.svg') no-repeat 0 0 / 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 111px;
        height: 111px;
        margin: 0 auto;
        background-color: var(--C-gray-300);
        vertical-align: baseline;
      }
      &_num {
        color: var(--C-white);
        line-height: 4rem;
        font-size: 3.8rem;
        font-weight: var(--font-weight-black);

        .percent {
          font-size: 2.6rem;
          line-height: 2.7rem;
        }
      }
      &_max {
        color: var(--C-white);
        line-height: 3.6rem;
        font-size: 2.8rem;

        .percent {
          font-size: var(--font-size-16);
        }
      }
      &_min {
        color: var(--C-white);
        line-height: 2.1rem;
        font-size: var(--font-size-16);
      }
      &_ani {
        @include position(0, null, null, 0);
        width: 200px;
        height: 160px;
      }
      &_logo {
        @include position(null, 34px, 16px);
      }
      &_title {
        margin: 0 16px;
        line-height: 2.8rem;
        font-size: var(--font-size-20);
        font-weight: var(--font-weight-bold);

        & span {
          font-weight: var(--font-weight-normal);
        }
      }
      &_point {
        font-weight: var(--font-weight-bold);
      }
      &_desc {
        display: inline-block;
        margin: 16px 24px 0;
        padding: 18px 20px;
        border-radius: 12px;
        box-sizing: border-box;
        background: var(--C-gray-100);
        color: var(--C-gray-600);
        line-height: 2rem;
        text-align: center;
        font-size: var(--font-size-14);
        white-space: pre-wrap;
      }
      &_btn {
        margin-top: 8px;
        padding: 8px 10px;
        border-radius: 6px;
        background: var(--C-primary-500);
        color: var(--C-white);
        line-height: 2rem;
        font-size: var(--font-size-14);
      }
    }
    &_body {
      margin-top: 8px;
      padding: 32px 20px;
      background: var(--C-white);

      &_title {
        font-weight: var(--font-weight-bold);
      }
      &_table {
        table-layout: fixed;
        width: 100%;
        margin-top: 24px;

        th {
          color: var(--C-gray-500);
          font-size: var(--font-size-14);
          line-height: 2rem;
          text-align: left;
        }
        thead {
          th {
            padding-bottom: 10px;
            border-bottom: 1px solid var(--C-gray-200);
          }
        }
        tbody {
          th, td {
            padding-top: 16px;
            vertical-align: top;
          }
          td {
            color: var(--C-gray-800);
            font-size: var(--font-size-14);
            word-break: keep-all;
          }
        }
      }
      &_checked {
        display: block;
        font-weight: var(--font-weight-bold);
        white-space: nowrap;

        &::before {
          @include set-bg(sign_check_1);
          display: inline-block;
          content: '';
          width: 16px;
          height: 16px;
          margin: 1px 5px 0 0;
          vertical-align: top;
        }
        &.fail::before {
          background-image: url("../../assets/images/sign_delete_1.svg");
        }
      }
    }
    &_footer {
      margin-top: 8px;
      background: var(--C-white);

      &_ul {
        padding: 0 20px 20px;
        color: var(--C-gray-600);
        line-height: 2rem;
        font-size: var(--font-size-14);
      }
      &_li {
        padding-left: 16px;
        text-indent: -16px;
      }
      &_table {
        padding: 16px 0;

        table {
          table-layout: fixed;
          width: 100%;

          &.type_small {
            thead {
              th {
                padding: 8px 5px;
              }
            }
            th, td {
              font-size: var(--font-size-11) !important;
              line-height: 1.6rem !important;
            }
          }
          th, td {
            padding: 8px 0;
            border: 1px solid var(--gray-100);
            line-height: 2rem;
            text-align: center;
            font-size: var(--font-size-14);
            word-break: keep-all;
          }
          thead {
            th {
              background: var(--gray-50);
              color: var(--gray-600);
            }
          }
          tbody {
            th, td {
              color: var(--gray-400);
            }
            .rate {
              color: var(--gray-600);
            }
          }
        }
        p {
          margin-top: 16px;
        }
      }
    }
    &_review {
      margin-top: 20px;
      padding: 0 20px;
      color: var(--C-gray-400);
      line-height: 1.6rem;
      text-align: center;
      font-size: var(--font-size-11);
      white-space: pre-line;
    }
  }
}
