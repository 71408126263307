@charset "UTF-8";

.vehicle {
  &_list {
    &_wrap {
      padding: 4px 20px 148px;
    }
    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      padding: 18px 20px;
      border: 1px solid var(--C-gray-400);
      border-radius: 8px;
      box-sizing: border-box;

      &.checked {
        border: 2px solid var(--C-primary-500);
        padding: 17px 19px;
      }
      .checked & {
        &_number {
          color: var(--C-primary-500);
        }
        &_detail {
          color: var(--C-primary-400);

          .bar::before {
            background: var(--C-primary-300);
          }
        }
      }
      &.disabled {
        border-color: var(--C-gray-200);
      }
      .disabled & {
        &_number {
          color: var(--C-gray-400);
        }
        &_detail {
          color: var(--C-gray-400);

          .bar::before {
            background: var(--C-gray-300);
          }
        }
      }
      &_info {
        flex: 1;
        padding-right: 10px;
      }
      &_number {
        font-weight: var(--font-weight-bold);
      }
      &_detail {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2px;
        color: var(--C-gray-500);
        line-height: 1.8rem;
        font-size: var(--font-size-12);

        .bar {
          display: flex;
          flex-direction: row;
          align-items: center;

          &:not(:first-of-type) {
            &::before {
              content: '';
              display: inline-block;
              width: 1px;
              height: 10px;
              margin: 0 5px;
              background: var(--C-gray-300);
            }
          }
        }
      }
    }
    &_desc {
      position: relative;
      margin-top: 12px;
      padding: 16px 16px 16px 42px;
      border-radius: 8px;
      background: var(--C-gray-100);
      color: var(--C-gray-600);
      line-height: 2rem;
      font-size: var(--font-size-14);

      & > i {
        @include position(16px, null, null, 16px);
      }
      &_emphasis {
        font-weight: var(--font-weight-bold);
      }
    }
  }
  &_spec {
    padding: 24px 0;

    &_detail {
      position: relative;
      padding: 0 16px 8px;
      box-sizing: border-box;

      &.is_img {
        height: 160px;
      }
      &_name {
        display: inline-flex;
        align-items: center;
        position: relative;
        z-index: 10;
        margin-bottom: 4px;
        line-height: 2.8rem;
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-20);

        span {
          flex: 1;
        }
        &::after {
          @include set-bg(ico_arrow_down);
          content: '';
          width: 16px;
          height: 16px;
          margin-left: 7px;
        }
      }
      &_num {
        position: relative;
        z-index: 10;
        padding-bottom: 16px;
        color: var(--C-gray-500);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-12);
      }
      &_img {
        @include position(null, 16px, 10px);
        z-index: 1;
      }
    }
    &_list {
      overflow: hidden;
      overflow-x: auto;
      padding: 0 16px;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
      &_item {
        display: inline-block;
        vertical-align: top;
        min-width: 96px;
        height: 66px;
        margin-left: 8px;
        padding: 12px 20px 0;
        border-radius: 8px;
        box-sizing: border-box;
        background: var(--C-gray-100);

        &:first-of-type {
          margin-left: 0;
        }
        &.more {
          padding: 0;
          border: 1px solid var(--C-gray-300);
          background: var(--C-white);

          & > button {
            display: flex;
            flex-direction: column;
            width: 96px;
            height: 66px;
            padding: 12px 0 0 20px;
          }
          .vehicle_spec_list_value {
            display: flex;
            align-items: center;

            &::after {
              @include set-bg(ico_16_right);
              content: '';
              width: 12px;
              height: 16px;
              margin-left: 2px;
            }
          }
        }
      }
      &_label {
        display: block;
        color: var(--C-gray-500);
        line-height: 1.6rem;
        font-size: var(--font-size-11);
      }
      &_value {
        display: block;
        margin-top: 2px;
        color: var(--C-gray-800);
        line-height: 2.4rem;
        font-weight: var(--font-weight-bold);
      }
    }
  }
  &_info {
    position: relative;
    padding: 24px 20px 24px 88px;
    word-break: keep-all;

    &.toll {
      padding: 0;

      & button {
        display: flex;
        align-items: center;
        padding: 24px 20px;
      }
      .vehicle_info_icon {
        position: static;
      }
      .vehicle_info_tit {
        margin-left: 20px;

        &::before {
          @include set-bg(ico_16_right);
          @include position(50%, 20px);
          content: '';
          transform: translateY(-50%);
          width: 12px;
          height: 16px;
        }
        &.none::before {
          display: none;
        }
      }
    }
    &.error,
    &.none {
      .vehicle_info_tit {
        color: var(--C-gray-400);
      }
    }
    // 세로 가운데 정렬에 사용
    &.align_center {
      display: flex;
      align-items: center;
      padding: 24px 20px;

      .vehicle_info_icon {
        position: static;
      }
      .vehicle_info_tit {
        margin-left: 20px;
      }
    }
    // 미납통행 로딩중
    &.loading {
      height: 96px;
      padding: 0;
    }
    & + & {
      &::before {
        @include position(0, 24px, null, 24px);
        content: '';
        border-top: 1px solid var(--C-gray-100);
      }
    }
    &_icon {
      @include position(24px, null, null, 22px);
    }
    &_tit {
      color: var(--C-black);
      line-height: 2.4rem;
      font-size: var(--font-size-16);
      font-weight: var(--font-weight-bold);
      white-space: pre-wrap;
      word-break: keep-all;

      &.is_tag {
        padding-right: 73px;
      }
    }
    &_point {
      color: var(--C-primary-500);

      &.warning {
        color: var(--C-orange-500);
      }
    }
    &_tip {
      display: flex;
      align-items: center;
      margin-top: 12px;
      color: var(--C-gray-700);
      line-height: 2rem;
      font-size: var(--font-size-14);

      &::after {
        content: '';
        width: 20px;
        height: 20px;
        margin-left: 2px;
        mask: url("../../assets/images/ico_question.svg") no-repeat 0 0 / 100% 100%;
        background-color: var(--C-gray-700);
      }
    }
    &_desc {
      margin-top: 8px;
      color: var(--C-gray-500);
      line-height: 2rem;
      font-size: var(--font-size-14);
      white-space: pre-wrap;
    }
    &_notice {
      color: var(--text-warning-default);
      line-height: 2rem;
      font-size: var(--font-size-14);
    }
    &_link {
      width: auto !important;
      height: 32px !important;
      margin-top: 12px !important;
      padding: 0 16px !important;
      border-radius: 6px !important;
      color: var(--C-gray-600) !important;
      font-size: var(--font-size-14) !important;

      &.red_dot {
        &::before {
          @include position(6px, 6px);
          content: '';
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: var(--C-red-400);
        }
      }
    }
    &_refresh {
      position: absolute !important;
      top: 24px;
      right: 20px;
      overflow: hidden;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: var(--C-gray-200);
      font-size: 1px;
      text-indent: -100px;

      &::before {
        @include position(0, 0, 0, 0);
        content: '';
        width: 20px;
        height: 20px;
        margin: auto;
        mask: url('../../assets/images/ico_refresh.svg') no-repeat 50% / 100%;
        background-color: var(--C-gray-600);
      }
    }
    &_icon {
      @include position(24px, null, null, 20px);
      width: 48px;
      height: 48px;
      background: no-repeat 0 0 / 100% 100%;

      &.warning {
        &::before {
          @include position(0, null, null, 0);
          @include set-bg(sign_warning_1);
          content: '';
          width: 16px;
          height: 16px;
        }
      }
      &.rank {
        background-image: url('../../assets/images/img_carcare_item_social.svg');
      }
      &.shipped {
        background-image: url('../../assets/images/img_carcare_item_date.svg');
      }
      &.driving {
        background-image: url('../../assets/images/img_carcare_item_mileage.svg');
      }
      &.price {
        background-image: url('../../assets/images/img_carcare_item_price.svg');
      }
      &.toll {
        background-image: url('../../assets/images/img_carcare_item_tollfee.svg');
      }
      &.inspection {
        background-image: url('../../assets/images/img_carcare_item_repair.svg');
      }
      &.insurance {
        background-image: url('../../assets/images/img_carcare_item_insure.svg');
      }
      &.warranty {
        background-image: url('../../assets/images/img_carcare_item_warranty.svg');
      }
      &.tire {
        background-image: url('../../assets/images/img_carcare_item_tire.svg');
      }
      &.engine_oil {
        background-image: url('../../assets/images/img_carcare_item_oil.svg');
      }
      &.recall {
        background-image: url('../../assets/images/img_carcare_item_recall.svg');
      }
      &.aircon_filter {
        background-image: url('../../assets/images/img_carcare_item_aircon_filter.svg');
      }
      &.air_filter {
        background-image: url('../../assets/images/img_carcare_item_air_filter.svg');
      }
    }
    &_benefit {
      padding: 0 20px 84px;

      // 보험정보를 표시할 수 없는 경우 여백 처리 위함
      &:first-of-type {
        margin-top: 24px;
      }
      &_inner {
        padding: 20px;
        border: 1px solid var(--C-gray-200);
        border-radius: 16px;
      }
      &_tit {
        margin-bottom: 16px;
        line-height: 2.4rem;
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-bold);
      }
    }
    &_tag {
      @include position(24px, 20px);
      height: 20px;
      padding: 0 4px;
      border-radius: 4px;
      background: var(--C-orange-500);
      color: var(--C-white);
      font-size: var(--font-size-12);
      font-weight: var(--font-weight-bold);
      line-height: 2rem;
    }
  }
  // 긴급호출
  &_emergency_call {
    @include position(null, 16px, 24px, null, 100);
    position: fixed !important;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 44px;
    padding: 0 16px 0 8px;
    border-radius: 22px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.14);
    background: var(--C-white);
    color: var(--C-gray-700);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-bold);

    &_img {
      margin-right: 8px;
    }
  }
  // 상세
  &_detail {
    padding-bottom: 96px; // body 영역의 기본 32px 제외

    &_header {
      padding: 24px 20px 0;

      &_visual {
        position: relative;
        width: 128px;
        height: 128px;
        margin: 0 auto 24px;

        &::after {
          @include position(0, 0);
          content: '';
          width: 28px;
          height: 28px;
          background: no-repeat 0 0 / 100% 100%;
        }
        &.warning::after {
          background-image: url("../../assets/images/sign_warning_1.svg");
        }
        &.none::after {
          background-image: url("../../assets/images/sign_question_1.svg");
        }
        &.normal::after {
          background-image: url("../../assets/images/sign_check_1.svg");
        }
      }
      &_tit {
        margin-top: 16px;
        line-height: 2.4rem;
        text-align: center;
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-bold);
        word-break: keep-all;

        .point {
          color: var(--C-primary-500);

          &.warning {
            color: var(--C-orange-500);
          }
        }
      }
      &_subtit {
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 40px;
        padding: 12px 0;
        text-align: left;
        line-height: 2.8rem;
        font-size: var(--font-size-20);
        font-weight: var(--font-weight-bold);

        .tip {
          overflow: hidden;
          width: 24px;
          height: 24px;
          margin-left: 4px;
          text-indent: -100px;
          font-size: 0;

          &::after {
            @include position(0, 0);
            content: '';
            width: 100%;
            height: 100%;
            mask: url("../../assets/images/ico_question.svg") no-repeat 0 0 / 100% 100%;
            background-color: var(--C-gray-700);
          }
        }
      }
      &_desc {
        margin-bottom: 32px;
        color: var(--C-gray-500);
        line-height: 2rem;
        font-size: var(--font-size-14);
      }
    }
    &_state {
      position: relative;
      margin: 24px 20px 32px;

      &_list {
        padding: 24px 16px 24px 20px;
        border: 1px solid var(--C-gray-200);
        border-radius: 12px;
      }
      &_item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 20px;

        &:first-of-type {
          margin-top: 0;
        }
      }
      &_label {
        position: relative;
        padding-right: 24px;
        color: var(--C-gray-500);
        line-height: 2.4rem;

        &::after {
          @include position(0, 0);
          content: '';
          width: 24px;
          height: 24px;
          mask: url("../../assets/images/ico_question.svg") no-repeat 0 0 / 100% 100%;
          background-color: var(--C-gray-700);
        }
        &.none::after {
          display: none;
        }
      }
      &_value {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        font-weight: var(--font-weight-bold);
        margin-left: 24px;
        text-align: right !important;

        &.none {
          color: var(--C-gray-400);
        }
        .tag {
          height: 20px;
          margin-right: 4px;
          padding: 0 4px;
          border-radius: 4px;
          background: var(--C-tmobi-green-200);
          color: var(--C-tmobi-green-600);
          line-height: 2rem;
          font-size: var(--font-size-12);
          font-weight: var(--font-weight-normal);
          white-space: nowrap !important;
        }
        .text {
          flex: 1;
        }
        .modify {
          @include set-bg(ico_pencil_solid);
          overflow: hidden;
          width: 18px;
          height: 18px;
          margin-left: 4px;
          text-indent: -100px;
          font-size: 0;
        }
      }
      &_tooltip {
        @include position(null, 0, 100%, null, 10);
        max-width: 320px;
        min-width: 180px;
        margin-bottom: 10px;
        padding: 12px 42px 12px 16px;
        border-radius: 8px;
        box-sizing: border-box;
        background: var(--C-primary-400);

        &::after {
          @include position(null, 24px, -10px);
          @include set-bg(tooltip_bubble);
          content: '';
          width: 12px;
          height: 10px;
        }
        .text {
          display: inline;
          color: var(--C-white);
          line-height: 2.4rem;
        }
        .close {
          @include position(12px, 16px);
          overflow: hidden;
          width: 22px;
          height: 22px;
          text-indent: -100px;
          font-size: 0;

          &::before {
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            mask: url("../../assets/images/ico_exit.svg") no-repeat 0 0 / 100% 100%;
            background-color: var(--C-white);
          }
        }
      }
    }
    // content(상세 정보)
    &_body {
      padding: 0 20px 32px;

      // footer button이 있는 경우
      &.is_button {
        padding-bottom: 120px; //플로팅 버튼은 88px 이지만 상단 영역에서 기본 32px를 제외한 높이값을 합산한 값
      }
      &_tit {
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 20px;
        padding: 12px 0;
        line-height: 2.8rem;
        font-size: var(--font-size-20);
        font-weight: var(--font-weight-bold);

        .tip {
          overflow: hidden;
          width: 24px;
          height: 24px;
          margin-left: 4px;
          text-indent: -100px;
          font-size: 0;

          &::after {
            @include position(0, 0);
            content: '';
            width: 100%;
            height: 100%;
            mask: url("../../assets/images/ico_question.svg") no-repeat 0 0 / 100% 100%;
            background-color: var(--C-gray-700);
          }
        }
      }
    }
    &_guide {
      &_tit {
        display: flex;
        align-items: center;
        position: relative;
        margin: 20px 0 -4px;
        padding: 12px 0;
        line-height: 2.8rem;
        font-size: var(--font-size-20);
        font-weight: var(--font-weight-bold);
      }
      &_desc {
        margin-top: 16px;
        color: var(--C-gray-500);
        line-height: 2.4rem;
      }
      &_img {
        margin-top: 16px;
        width: 100%;
        max-width: 400px;
      }
      &_check {
        display: flex;
        align-items: center;
        margin-top: 16px;
        padding: 16px;
        border-radius: 16px;
        background: var(--C-primary-200);

        .text {
          flex: 1;
          margin-left: 8px;
          color: var(--C-gray-700);
          line-height: 2rem;
          font-size: var(--font-size-14);
        }
        .point {
          font-weight: var(--font-weight-bold);
        }
      }
    }
    // gauge
    &_gauge {
      overflow: hidden;
      padding: 12px 0 4px;

      &_status {
        @include flex;
        @include flex-align(center);
        @include flex-justify(space-between);
        min-height: 2.4rem;
        color: var(--C-gray-500);
        line-height: 2.4rem;
        font-size: var(--font-size-18);

        .current {
          color: var(--C-primary-500);
          font-weight: var(--font-weight-bold);

          &.warning {
            color: var(--C-orange-500);
          }
        }
        .remain {
          color: var(--C-gray-900);
          font-weight: var(--font-weight-bold);
          font-size: var(--font-size-14);
        }
      }
      &_bar {
        position: relative;
        height: 16px;
        margin-top: 8px;
        border-radius: 33px;
        background: var(--C-gray-200);

        .gauge {
          @include position(0, null, null, 0);
          height: 100%;
          border-radius: 33px;
          background: var(--C-primary-400);
          transition: 1s;

          &.warning {
            background-color: var(--C-orange-400);
          }
          &.infinity {
            background: repeating-linear-gradient(130deg, #5B9DFF, #5B9DFF 8px, var(--C-primary-400) 0, var(--C-primary-400) 16px);
          }
        }
      }
      &_total {
        display: flex;
        align-items: center;
        float: right;
        height: 36px;
        margin-right: -12px;
        padding: 0 12px;
        color: var(--C-gray-500);
        font-size: var(--font-size-14);

        &::after {
          @include set-bg(ico_pencil_solid);
          content: '';
          width: 18px;
          height: 18px;
          margin-left: 2px;
        }
        &:disabled {
          &::after {
            display: none;
          }
        }
        .point {
          color: var(--C-gray-900);
          font-weight: var(--font-weight-bold);
        }
      }
    }
    // 리콜정보
    &_recall {
      margin-top: 16px;

      &_sources {
        color: var(--C-gray-500);
        line-height: 1.8rem;
        text-align: right;
        font-size: var(--font-size-12);

        .link {
          margin-left: 4px;
          color: var(--C-primary-600);
          font-size: var(--font-size-12);
        }
      }
      &_list {
        margin-top: 8px;
      }
      &_item {
        overflow: hidden;
        margin-top: 8px;
        padding: 20px 57px 20px 20px;
        border-radius: 12px;
        background: var(--C-gray-100);

        &::before {
          @include position(0, 20px, 0);
          content: '';
          width: 16px;
          height: 16px;
          margin: auto 0;
          mask: url("../../assets/images/ico_arrow_right.svg") no-repeat 0 0 / 100% 100%;
          background-color: var(--C-gray-500);
        }
      }
      &_tit {
        line-height: 2.4rem;
        font-weight: var(--font-weight-bold);
      }
      &_info {
        display: flex;
        position: relative;
        margin-top: 8px;
        line-height: 2rem;

        .state {
          width: 105px;
        }
        .text {
          height: 20px;
          padding: 0 4px;
          border-radius: 4px;
          background: var(--C-red-200);
          color: var(--C-red-600);
          font-size: var(--font-size-12);
          font-weight: var(--font-weight-bold);

          &.complete {
            background: var(--C-gray-200);
            color: var(--C-gray-700);
          }
        }
        .date {
          display: inline-block;
          color: var(--C-gray-500);
          line-height: 2rem;
          font-size: var(--font-size-14);
        }
      }
    }
    // 데이터가 없는 경우
    &_empty {
      padding-top: 64px;
      text-align: center;

      &::before {
        @include set-bg(img_case_notice);
        display: inline-block;
        content: '';
        width: 64px;
        height: 64px;
      }
      &_tit {
        margin-top: 32px;
        color: var(--C-gray-700);
        line-height: 2.4rem;
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-bold);
      }
      &_desc {
        margin-top: 8px;
        color: var(--C-gray-500);
        line-height: 2.4rem;
      }
    }
  }
  // 등록된 차량이 없는 경우
  &_none {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 56px);

    // header에 탭이 있는 경우
    &.is_tab {
      height: calc(100vh - 112px);
    }
    &_desc {
      margin-top: 12px;
      color: var(--C-gray-700);
      line-height: 2.4rem;
    }
    &_register {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 144px;
      height: 40px;
      margin-top: 40px;
      border-radius: 4px;
      background: var(--C-primary-500);
      color: var(--C-white);
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-14);

      &:before{
        @include set-bg(ico_plus);
        content: '';
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }

  &_unpaid_toll {
    padding: 20px;

    &_summary {
      &.title {
        font-size: var(--font-size-24);
        font-weight: var(--font-weight-black);

        span {
          color: var(--tmobi-blue-500);
        }
      }

      &.sub {
        margin-top: 12px;
        color: var(--text-basic-description);
        height: 26px;
        line-height: 2.6rem;
      }
    }

    &_list {
      &_check {
        &_all,
        &_item {
          display: block;
          position: relative;
          padding: 20px 0 20px 48px;
          border-bottom: 1px solid var(--C-gray-200);
          margin-top: 20px;
          width: 100%;
          text-align: left;

          &_label {
            display: block;
            font-size: var(--font-size-16);
            font-weight: var(--font-weight-bold);
            color: var(--C-gray-900);
            line-height: 2.4rem;

            &::before {
              content: "";
              width: 20px;
              height: 20px;
              @include position(calc(50% - 10px), 0, null, 16px);
              @include set-bg(checkbox_s_off);
            }

            &.checked {
              &::before {
                @include set-bg(checkbox_s_on);
              }
            }

            .title {
              display: flex;
              justify-content: space-between;

              p:nth-of-type(2) {
                color: var(--text-basic-accent);
                font-weight: var(--font-weight-bold);
              }
            }

            .sub {
              display: flex;
              justify-content: space-between;
              color: var(--text-basic-caption);
              font-size: var(--font-size-14);
            }
          }
        }

        &_item {
          margin-top: 0;
        }
      }
    }

    &_none {
      position: relative;
      height: calc(100vh - 318px);
      box-sizing: border-box;

      &_msg {
        @include position(0, 0, 0, 0);
        @include flex;
        @include flex-direction(column);
        @include flex-justify(center);
        @include flex-align(center);
        color: var(--C-gray-700);
        line-height: 2.4rem;
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-bold);
        text-align: center;

        &::before {
          @include set-bg(nothing_1);
          content: '';
          width: 64px;
          height: 64px;
          margin-bottom: 32px;
        }
      }
    }

    &_notice {
      position: relative;
      padding: 16px 16px 16px 42px;
      background-color: var(--gray-50);
      border-radius: 8px;
      color: var(--gray-500);
      font-size: var(--font-size-14);

      &.space {
        margin: 20px 0 100px;
      }

      &::before {
        @include position(16px, null, null, 16px);
        @include set-bg(ico_information);
        content: '';
        display: block;
        width: 22px;
        height: 22px;
      }

      button {
        margin-top: 20px;
        color: var(--text-basic-link);
        font-size: var(--font-size-14);
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-thickness: 1px;
      }
    }

    &_result {
      height: calc(100vh - 240px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      p:nth-of-type(1) {
        margin-top: 32px;
        font-weight: var(--font-weight-bold);
      }
      p:nth-of-type(2) {
        margin-top: 8px;
        font-size: var(--font-size-14);
        color: var(--gray-500);
      }

      &_btn {
        border-radius: 8px;
        height: 52px;
      }
    }
  }
}
